const moment = require('moment-timezone');
import api from "@/services/api";
import httpClient from "@/services/httpClient";
import {
  server
} from "@/services/constants";
import router from "@/router";
import * as productApis from "@/services/api-dtime/api_product.js";
import * as masterApis from "@/services/api-dtime/api_master.js";
import * as accountApis from "@/services/api-dtime/api_account.js";
import * as customerApis from "@/services/api-dtime/api_customer.js";
import * as currencyApis from "@/services/api-dtime/api_currency.js";
import * as groupMenuApis from "@/services/api-dtime/api_group_menu.js";
import * as settingGroupMenuApis from "@/services/api-dtime/api_setting_group_menu.js";
import * as menuDetailApis from "@/services/api-dtime/api_menu_detail.js";
import * as settingMenuDetailApis from "@/services/api-dtime/api_setting_menu_detail.js";
import * as advanceApis from "@/services/api-dtime/api_advance.js";
import * as advanceHApis from "@/services/api-dtime/api_advance_h.js";
import * as advanceDApis from "@/services/api-dtime/api_advance_d.js";
import * as advanceCodeApis from "@/services/api-dtime/api_advance_code.js";
import * as advanceLogApis from "@/services/api-dtime/api_advance_log.js";
import * as departmentApis from "@/services/api-dtime/api_department.js";
import * as salesorderApis from "@/services/api-dtime/api_sales_order.js";
import * as salesorderdetailApis from "@/services/api-dtime/api_sales_order_d.js";
import * as companyApis from "@/services/api-dtime/api_company.js";
import * as positionApis from "@/services/api-dtime/api_position.js";
import * as jobtitleApis from "@/services/api-dtime/api_jobtitle.js";
import * as timsheetHApis from "@/services/api-dtime/api_timesheet_h.js";
import * as timsheetDApis from "@/services/api-dtime/api_timesheet_d.js";
import * as activityApis from "@/services/api-dtime/api_activity.js";
import * as leavequotaApis from "@/services/api-dtime/api_leavequota.js";
import * as leaveApis from "@/services/api-dtime/api_leave.js";
import * as api_LEAVE_HISTORY_QUOTA_DETAIL from "@/services/api-dtime/api_leave_history_quota_detail.js";
import * as api_leave_holiday from "@/services/api-dtime/api_leave_holiday.js";
import * as invoice_hApis from "@/services/api-dtime/api_invoice_h.js";
import * as api_inv_soApis from "@/services/api-dtime/api_inv_so.js";
import * as api_inv_docrefApis from "@/services/api-dtime/api_inv_docref.js";
import * as api_leave_approve_detail from "@/services/api-dtime/api_leave_approve_detail.js";
import * as api_inv_miscellaneous from "@/services/api-dtime/api_inv_miscellaneous.js";
import * as api_trc_h from "@/services/api-dtime/api_trc_h.js";
import * as api_trc_d from "@/services/api-dtime/api_trc_d.js";
import * as api_inv_ts from "@/services/api-dtime/api_inv_ts.js";
import * as api_inv_title from "@/services/api-dtime/api_inv_title.js";
import * as api_im_principal from "@/services/api-dtime/api_im_principal.js";
import * as api_im_participant from "@/services/api-dtime/api_im_participant.js";


import * as api_doctype from "@/services/api-dtime/api_doctype.js";
import * as api_doctitle_template from "@/services/api-dtime/api_doctitle_template.js";
import * as api_docdropdown from "@/services/api-dtime/api_docdropdown.js";
import * as api_doctrans from "@/services/api-dtime/api_doctrans.js";
import * as api_doctrans_h from "@/services/api-dtime/api_doctrans_h.js";

import * as api_im_transaction from "@/services/api-dtime/api_im_transaction.js";
import * as api_im_transaction_dependent from "@/services/api-dtime/api_im_transaction_dependent.js";
import * as api_miscellaneous_list from "@/services/api-dtime/api_miscellaneous_list.js";
import * as im_master_place_of_issue from "@/services/api-dtime/im_master_place_of_issue.js";
import * as im_master_work_place_of_issue from "@/services/api-dtime/im_master_work_place_of_issue.js";
import * as api_tranning_h from "@/services/api-dtime/api_tranning_h.js";
import * as api_tranning_d from "@/services/api-dtime/api_tranning_d.js";
import * as api_tranning_email from "@/services/api-dtime/api_tranning_email.js";
import * as api_tranning_user from "@/services/api-dtime/api_tranning_user.js";
import * as api_tranning_user_d from "@/services/api-dtime/api_tranning_user_d.js";
import * as api_transactions_log from "@/services/api-dtime/api_transaction_log.js";
import * as api_log_in_out_log from "@/services/api-dtime/api_log_in_out_log.js";
import * as api_pettycash_h from "@/services/api-dtime/api_pettycash_h.js";
import * as api_pettycash_d from "@/services/api-dtime/api_pettycash_d.js";
import * as api_file_attach from "@/services/api-dtime/api_file_attach.js";
import * as api_register_company from "@/services/api-dtime/api_register_company.js";
import * as api_leave_type from "@/services/api-dtime/api_leave_type.js";
import * as api_doc_running from "@/services/api-dtime/api_docrunning.js";
import * as api_approver_management from "@/services/api-dtime/api_approver_management.js";



////---------------at law services--------------///
import * as mastertmApis from "@/services/api-ip-at-law/api_mastertm.js";
import * as objecttypeApis from "@/services/api-ip-at-law/api_objecttype.js";
import * as contactApis from "@/services/api-ip-at-law/api_contact.js";
import * as tmupdatestatusApis from "@/services/api-ip-at-law/api_tmupdatestatus.js";
import * as masterptApis from "@/services/api-ip-at-law/api_masterpt.js";
import * as documenttypeApis from "@/services/api-ip-at-law/api_documenttype.js";
import * as appointmentApis from "@/services/api-ip-at-law/api_appointment.js";
import * as countryApis from "@/services/api-ip-at-law/api_country.js";
import * as noticeApis from "@/services/api-ip-at-law/api_notice.js";
import * as POAApis from "@/services/api-ip-at-law/api_poa.js";
import * as OCRApis from "@/services/api-ip-at-law/api_ocr.js";
import * as OCRKWApis from "@/services/api-ip-at-law/api_ocrkw.js";
import * as api_ip_prcie_list from "@/services/api-ip-at-law/api_ip_prcie_list.js";


const isLoggedIn = () => {
  let token = localStorage.getItem(server.TOKEN_KEY);
  return token != null;
};

const login = async (values) => {
  try {
    let result = await httpClient.post(
      server.ACCOUNT_URL + "/loginforweb",
      values
    );
    // console.log(result);
    if (result.data.token != null || result.data.token != "") {
      localStorage.setItem(server.USERNAME, values.username);
      localStorage.setItem(server.USER_ID, result.data.user_id);
      localStorage.setItem(server.TOKEN_KEY, result.data.token);
      localStorage.setItem(server.FULLNAME, result.data.acc_fullname !== "  "?result.data.acc_fullname:result.data.acc_fullname_en !== "  "?result.data.acc_fullname_en:"");
      localStorage.setItem(server.ROLE, result.data.role);
      localStorage.setItem(server.COMPANYID, result.data.company_id);
      localStorage.setItem(server.DEPARTMENT_ID, result.data.department_id);
      localStorage.setItem(server.POSITION_ID, result.data.position_id);
      localStorage.setItem(server.AUTHORIZE_ID, result.data.authorize_id);
      localStorage.setItem(server.EMAIL_URL, result.data.email);
      localStorage.setItem(server.VERSION, result.data.v_web);
      localStorage.setItem(server.APPROVE_LEVEL1, result.data.approver_level1);
      localStorage.setItem(
        server.APPROVE_LEVEL1_EMAIL,
        result.data.approver_level1_email
      );
      localStorage.setItem(server.LOGIN_ID,result.data.login_id);
      
      // localStorage.setItem(server.COMPANY_NAME, result.data.company_name);
      // localStorage.setItem(server.DEPARTMENT_NAME, result.data.department_name);
      // localStorage.setItem(server.ACC_PLAN_STA, result.data.acc_plan);

      // router.push("/dashboard");
      return true;
    } else if(result.status == 503 && result.data.ip_error){
      alert(result.data.ip_error);
      return false;
    }
    else {
      alert("ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง");
      // console.log("login log : " + "result error");
      return false;
    }
  } catch (error) {
    if(error.response.status == 503 && error.response.data.ip_error){
      alert(error.response.data.ip_error);
    }else{
      alert("ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง");
    }
    return false;
  }
};

const register = async (values) => {
  let result = await httpClient.post(server.REGISTER_URL, values);
  if (result.data.result == "ok") {
    router.go(-1);
  } else {
    alert(JSON.stringify(result));
  }
};

const logoff = () => {
  localStorage.removeItem(server.TOKEN_KEY);
  localStorage.clear();
  router.push("/login");
};

const checkVersion = async() => {
  try {
    const version = localStorage.getItem(server.VERSION);
    // console.log("checkVersion version:", version);
    if (version !== "106") {

      var dataEdit = {
        date_out: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
      }
      await api.updateLogInOutLog(localStorage.getItem(server.LOGIN_ID),dataEdit)

      // this.$store.dispatch("doLogout");
      localStorage.removeItem(server.TOKEN_KEY);
      localStorage.clear();
      // router.push("/login");
    }
  } catch (error) {
    var dataEdit = {
      date_out: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
    }
    await api.updateLogInOutLog(localStorage.getItem(server.LOGIN_ID),dataEdit)
    
    // this.$store.dispatch("doLogout");
    localStorage.removeItem(server.TOKEN_KEY);
    localStorage.clear();
    // router.push("/login");
    console.log("checkVersion error:", error);
  }
};

export default {
  isLoggedIn,
  login,
  register,
  logoff,
  checkVersion,
  ...productApis,
  ...accountApis,
  ...customerApis,
  ...currencyApis,
  ...groupMenuApis,
  ...menuDetailApis,
  ...settingGroupMenuApis,
  ...settingMenuDetailApis,
  ...masterApis,
  ...advanceApis,
  ...advanceHApis,
  ...advanceDApis,
  ...advanceCodeApis,
  ...advanceLogApis,
  ...departmentApis,
  ...salesorderApis,
  ...salesorderdetailApis,
  ...companyApis,
  ...positionApis,
  ...jobtitleApis,
  ...timsheetHApis,
  ...timsheetDApis,
  ...activityApis,
  ...leavequotaApis,
  ...leaveApis,
  ...invoice_hApis,
  ...api_inv_soApis,
  ...api_inv_docrefApis,
  ...api_leave_approve_detail,
  ...api_inv_miscellaneous,
  ...api_trc_h,
  ...api_trc_d,
  ...api_inv_ts,
  ...api_im_principal,
  ...api_im_participant,
  ...api_doctype,
  ...api_doctitle_template,
  ...api_docdropdown,
  ...api_doctrans,
  ...api_im_transaction,
  ...api_im_transaction_dependent,
  ...api_doctrans_h,
  ...api_inv_title,
  ...api_miscellaneous_list,
  ...im_master_place_of_issue,
  ...im_master_work_place_of_issue,
  ...api_LEAVE_HISTORY_QUOTA_DETAIL,
  ...api_leave_holiday,
  ...api_transactions_log,
  ...api_log_in_out_log,
  ...api_tranning_h,
  ...api_tranning_d,
  ...api_tranning_email,
  ...api_tranning_user,
  ...api_tranning_user_d,
  ...api_pettycash_h,
  ...api_pettycash_d,
  ...api_file_attach,
  ...api_register_company,
  ...api_leave_type,
  ...api_doc_running,
  ...api_approver_management,

////--------------at la service---------------------////
  ...mastertmApis,
  ...objecttypeApis,
  ...contactApis,
  ...tmupdatestatusApis,
  ...masterptApis,
  ...documenttypeApis,
  ...appointmentApis,
  ...countryApis,
  ...noticeApis,
  ...POAApis,
  ...OCRApis,
  ...OCRKWApis,
  ...api_ip_prcie_list,
  
};