var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"pa-16"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Department Activity")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{staticClass:"widthsizesearch",staticStyle:{"width":"100%"},attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.charge_list,"label":"Charge Type","item-text":"name","item-value":"value","outlined":"","dense":"","clearable":""},on:{"change":_vm.findChaege,"click:clear":function($event){_vm.$nextTick(function () {
                _vm.searchCharge = null;
            })}},model:{value:(_vm.searchCharge),callback:function ($$v) {_vm.searchCharge=$$v},expression:"searchCharge"}})],1),_c('v-col',{staticClass:"d-flex justify-end mb-6",attrs:{"cols":"12","md":"5"}},[_c('v-btn',{staticClass:"mr-2",on:{"click":_vm.showsearchmore}},[_vm._v(" "+_vm._s(_vm.setlanguage.adddata)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(_vm._s(_vm.seticonsearchmore.icon))])],1)],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.toggleEnable),expression:"toggleEnable"}]},[_c('v-col',{attrs:{"align":"right","cols":"12","xl":"3","lg":"3","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.Emp_list,"label":"Employee","item-text":"name","item-value":"id","outlined":"","dense":"","multiple":""},on:{"change":_vm.findChaege},model:{value:(_vm.searchEmp),callback:function ($$v) {_vm.searchEmp=$$v},expression:"searchEmp"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.jobTitle_list,"label":"Job Title / Case","item-text":"name","item-value":"name","outlined":"","dense":"","clearable":""},on:{"change":_vm.findChaege,"click:clear":function($event){_vm.$nextTick(function () {
            _vm.searchChargeJob = null;
          })}},model:{value:(_vm.searchChargeJob),callback:function ($$v) {_vm.searchChargeJob=$$v},expression:"searchChargeJob"}})],1),_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"3","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"DateFrom","append-icon":"mdi-calendar","readonly":"","outlined":"","dense":"","clearable":""},on:{"click:clear":function($event){_vm.$nextTick(function () {
                  _vm.datefrom = null;
                  _vm.dateto = null;
                })},"input":_vm.finddatafromdate},model:{value:(_vm.computeddatefromFormatted),callback:function ($$v) {_vm.computeddatefromFormatted=$$v},expression:"computeddatefromFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu = false},"change":_vm.finddatafromdate},model:{value:(_vm.datefrom),callback:function ($$v) {_vm.datefrom=$$v},expression:"datefrom"}})],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"3","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"DateTo","append-icon":"mdi-calendar","readonly":"","outlined":"","dense":"","clearable":""},on:{"click:clear":function($event){_vm.$nextTick(function () {
                  _vm.datefrom = null;
                  _vm.dateto = null;
                })},"input":_vm.finddatafromdate},model:{value:(_vm.computeddateToFormatted),callback:function ($$v) {_vm.computeddateToFormatted=$$v},expression:"computeddateToFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false},"change":_vm.finddatafromdate},model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}})],1)],1)],1),_c('br'),_c('v-card',{attrs:{"outlined":"","tile":""}},[_c('v-data-table',{staticClass:"   595959",attrs:{"dense":"","search":_vm.search,"headers":_vm.headers,"items":_vm.mDataArray},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [(_vm.authorize_edit)?_c('v-icon',{style:({
                            backgroundColor: '#E1F5FE',
                            color: '#254E58',
                        }),on:{"click":function($event){return _vm.openeditItem(item.id)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.authorize_del)?_c('v-icon',{staticClass:"ml-5",style:({
                            backgroundColor: '#FFEBEE',
                            color: '#9E3B1B',
                        }),on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }