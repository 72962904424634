//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server, imageUrl } from "@/services/constants";
export default {
  components: {
    SuccessDialog,
  },
  data: () => ({
    rules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Image size should be less than 2 MB!",
    ],
    dialogAdd: false,
    text_color: "text-h5 green--text text-center",
    title: "green",
    message: "green",
    search: "",
    dialogadd: false,
    dialogedit: false,
    dialogAddCom: false,
    headers: [
      { text: "ID", value: "id" },
      { text: "Code", value: "code" },
      { text: "Name", value: "name" },
      // { text: "address", value: "address" },
      // { text: "provice", value: "provice" },
      // { text: "zipcode", value: "zipcode" },
      { text: "Status", value: "status" },
      { text: "Action", value: "actions" },
    ],
    desserts: [],
    itemadd: {
      code: "",
      name: "",
      company_address: "",
      company_branch: "",
      company_email: "",
      company_tel: "",
      inv_company_name: "",
      inv_company_name_th: "",
      inv_company_address: "",
      inv_company_branch: "",
      inv_company_email: "",
      inv_company_tel: "",
      inv_tax_id: "",
      status: "",
      image: null,
    },
    itemeditid: 0,
    itemedit: {
      code: "",
      name: "",
      company_address: "",
      company_branch: "",
      company_email: "",
      company_tel: "",
      inv_company_name: "",
      inv_company_name_th: "",
      inv_company_address: "",
      inv_company_branch: "",
      inv_company_email: "",
      inv_company_tel: "",
      inv_tax_id: "",
      status: "",
      image: null,
    },
    itemdocedit: {
      module: "Employee",
      id_prefix: "",
      running_year: new Date().getFullYear().toString().slice(-2),
      running_len: 1,
      running_next: 1,
      doc_status: 0,
    },
    itemAddCom: {
      code: "",
      name: "",
      company_address: "",
      company_branch: "",
      company_email: "",
      company_tel: "",
      inv_company_name: "",
      inv_company_name_th: "",
      inv_company_address: "",
      inv_company_branch: "",
      inv_company_email: "",
      inv_company_tel: "",
      inv_tax_id: "",
      status: "ACTIVE",
      image: null,
      module: "Employee",
      id_prefix: "",
      running_year: new Date().getFullYear().toString().slice(-2),
      running_len: 1,
      running_next: 1,
      doc_status: 0,
    },
    statusall: ["ACTIVE", "INACTIVE"],
    emp_assign: [
      { name:"Manual", values: 1}, 
      { name:"Auto-generated", values: 0},
    ],
    disabled_status: false,
    image_link: "",
    checkSameComInv: false,
    checkSameComInvAdd: false,
    numberRules: [
      (v) => !v || /^[0-9]+$/.test(v) || "Please Input Number Only",
    ],
    telRules: [
    (v) => !v || /^[+]?([0-9,-]+)$/.test(v) || "Please Input Number Only",
    ],
    emailRules: [
      (v) =>
        !v ||
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "Please enter a valid email address",
    ],
    comcodeRules: [
      (v) => !v || v.length > 6 ? "Please check that the Company Code" : true,
    ],
    isShowPassword: false,
    isShowPassconfirmword: false,
    Firstname: "",
    Lastname: "",   
    Email: "",
    Username: "",
    Password: "",
    Confirm_Password: "",
  }),
  async mounted() {
    await api.checkVersion();
    // ----------------- Check Authorize ---------------------------
    const userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);
    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed",
        "Please Logout And Login Again",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(userId, router_path);

    // console.log("res_auth:" + JSON.stringify(res_auth.data));

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    if (localStorage.getItem(server.ROLE) != "ADMIN") {
      this.authorize_add = false;
      this.disabled_status = true;
    }

    if (!this.authorize_view) {
      // console.log("res_auth:" + JSON.stringify(res_auth.data));
      // console.log("authorize_view:" + this.authorize_view);
      // console.log("authorize_add:" + this.authorize_add);
      // console.log("authorize_edit:" + this.authorize_edit);
      // console.log("authorize_del:" + this.authorize_del);
      // this.$router.back();

      this.$router.back();
    }

    // ----------------- Check Authorize ---------------------------

    let token = localStorage.getItem(server.USER_ID);

    if (userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }

    await this.getcompanydata();
  },
  watch: {
            checkSameComInv(newVal) {
             this.checkSameComInvChanged(newVal);
            },
            checkSameComInvAdd(newVal) {
             this.checkSameComInvChangedAdd(newVal);
            },
        },
  methods: {
    openAddCom(){
      this.Firstname = "";
      this.Lastname = "";
      this.Email = "";
      this.Username = "";
      this.Password = "";
      this.Confirm_Password = "";
      this.itemAddCom = {
        code: "",
        name: "",
        company_address: "",
        company_branch: "",
        company_email: "",
        company_tel: "",
        inv_company_name: "",
        inv_company_name_th: "",
        inv_company_address: "",
        inv_company_branch: "",
        inv_company_email: "",
        inv_company_tel: "",
        inv_tax_id: "",
        status: "ACTIVE",
        image: null,
        module: "Employee",
        id_prefix: "",
        running_year: new Date().getFullYear().toString().slice(-2),
        running_len: 1,
        running_next: 1,
        doc_status: 0,
      }
      this.dialogAddCom = true;
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    async getcompanydata() {
      if (localStorage.getItem(server.ROLE) == "ADMIN") {
        const result = await api.getAllCompany();
        this.desserts = result.data;
      } else {
        const result = await api.getCompanyByID(
          localStorage.getItem(server.COMPANYID)
        );
        this.desserts = result.data;
      }

      // this.$showLoader();
      this.$hideLoader();
    },

    openadd() {
      this.itemadd = {
        code: "",
        name: "",
        status: "",
      };
      this.dialogadd = true;
    },
    canceladd() {
      this.dialogadd = false;
    },
    cancelAdd() {
      this.dialogAddCom = false;
    },
    canceledit() {
      this.dialogedit = false;
    },
    async savedata() {
      if (this.itemadd.code == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter Company Code",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.itemadd.name == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter Company Name",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.itemadd.status == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please select Status",
          "text-h5 red--text text-center"
        );
        return;
      }

      let formData = new FormData();
      formData.append("code", this.itemadd.code);
      formData.append("name", this.itemadd.name);
      formData.append("status", this.itemadd.status);
      // formData.append("image", this.itemadd.image);

      if (this.itemadd.image != null || this.itemadd.image != "") {
        formData.append("image", this.itemadd.image);
      }

      // const res_add = await api.addCompany(this.itemadd);
      const res_add = await api.addCompany(formData);
      if (res_add.status == 200 || res_add.status == 201) {
        //   alert("debug1");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Success",
          "Add data success",
          "text-h5 green--text text-center"
        );
        this.getcompanydata();
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Add data Failed",
          "text-h5 red--text text-center"
        );
      }
      this.dialogadd = false;
    },

    async openeditItem(item) {
      this.itemeditid = item.id;
      this.itemedit.code = item.code;
      this.itemedit.name = item.name;
      this.itemedit.company_address=item.company_address,
      this.itemedit.company_branch=item.company_branch,
      this.itemedit.company_email=item.company_email,
      this.itemedit.company_tel=item.company_tel,
      this.itemedit.inv_company_name=item.inv_company_name,
      this.itemedit.inv_company_name_th=item.inv_company_name_th,
      this.itemedit.inv_company_address=item.inv_company_address,
      this.itemedit.inv_company_branch=item.inv_company_branch,
      this.itemedit.inv_company_email=item.inv_company_email,
      this.itemedit.inv_company_tel=item.inv_company_tel,
      this.itemedit.inv_tax_id=item.inv_tax_id,
      this.itemedit.status = item.status;
      if (item.image != null) {
        // this.itemedit.image = item.image;
        this.image_link = imageUrl + "/" + item.image;
      } else {
        this.image_link = imageUrl + "/personal.png";
        // this.itemedit.image = null;
      }
      // alert(this.image_link);
      // this.itemedit.image = item.image;
      this.itemdocedit = {
        module: "Employee",
        id_prefix: "",
        running_year: new Date().getFullYear().toString().slice(-2),
        running_len: 1,
        running_next: 1,
        doc_status: 0
      }

      this.$showLoader()
      const resDoc = await api.fineDocRunningByModlue("Employee",item.id)
      console.log("resDoc.data[0]")
      console.log(resDoc.data)
      if(resDoc.data){
        this.itemdocedit = {
          module: resDoc.data.module,
          id_prefix: resDoc.data.id_prefix,
          running_year: resDoc.data.running_year,
          running_len: resDoc.data.running_len,
          running_next: resDoc.data.running_next,
          doc_status: resDoc.data.check_doc_auto,
        }
      }
      this.$hideLoader()
      this.dialogedit = true;
    },
    async savedataAdd() {
      if(this.Firstname.trim() == ""){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
          true,
          "Failed",
          "Please Check Firstname Data",
          "text-h5 red--text text-center"
          );
          this.$refs["Firstname"].focus();
          return;
      }
      if(this.Lastname.trim() == ""){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
          true,
          "Failed",
          "Please Check Lastname Data",
          "text-h5 red--text text-center"
          );
          this.$refs["Lastname"].focus();
          return;
      }
      if(
          this.Email.trim() == "" ||
          !/^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.Email.trim())
        ){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
          true,
          "Failed",
          "Please Check Email Data",
          "text-h5 red--text text-center"
          );
          this.$refs["Email"].focus();
          return;
      }else{
        const res_check_mail = await api.getUserByEmail(this.Email.trim())
        if(res_check_mail.data.length > 0){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
          true,
            "Failed",
            "Email Duplicated, Please Check Email",
            "text-h5 red--text text-center"
            );
          this.$refs["Email"].focus();
          return;
        }
      }
      if(this.Username.trim() == ""){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
          true,
          "Failed",
          "Please Check Username Data",
          "text-h5 red--text text-center"
          );
          this.$refs["Username"].focus();
          return;
      }
      if(this.Password.trim() == ""){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
          true,
          "Failed",
          "Please Check Password Data",
          "text-h5 red--text text-center"
          );
          this.$refs["Password"].focus();
          return;
      }
      if(this.Confirm_Password.trim() == ""){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
          true,
          "Failed",
          "Please Check Confirm Password Data",
          "text-h5 red--text text-center"
          );
          this.$refs["Confirm_Password"].focus();
          return;
      }
      if(this.Password.trim() != this.Confirm_Password.trim()){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed",
                    "Please Check Password and Confirm Password",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["Confirm_Password"].focus();
                    return;
      }
      if(this.itemAddCom.name.trim() == ""){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
          true,
          "Failed",
          "Please Check Company Name Data",
          "text-h5 red--text text-center"
          );
          this.$refs["Company_Name_Add"].focus();
          return;
      }
      if(this.itemAddCom.company_address.trim() == ""){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
          true,
          "Failed",
          "Please check the invoice information again.",
          "text-h5 red--text text-center"
          );
          this.$refs["Company_Address_Add"].focus();
          return;
      }
      if(this.itemAddCom.company_branch.trim() == ""){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
          true,
          "Failed",
          "Please Check Branch Data",
          "text-h5 red--text text-center"
          );
          this.$refs["Company_Branch_Add"].focus();
          return;
      }
      if (
          this.itemAddCom.company_email.trim() === "" || 
          !/^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.itemAddCom.company_email.trim())
        ) {
          
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
              true,
              "Failed",
              "Please Check Company Email Data",
              "text-h5 red--text text-center"
          );
          this.$refs["Company_Email_Add"].focus();
          return;
      }else{
        const res_check_mail_com = await api.getCompanyCol(this.itemAddCom.company_email,`company_email`)

        if(res_check_mail_com.data.length > 0){
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
            true,
                "Failed",
                "Email Duplicated, Please Check Email",
                "text-h5 red--text text-center"
                );
            this.$refs[`Company_Email_Add`].focus();
            return;
        }
      }
      if(
        this.itemAddCom.company_tel.trim() == "" ||
        !/^[+]?([0-9,-]+)$/.test(this.itemAddCom.company_tel)
      ){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
          true,
          "Failed",
          "Please Check Telephone Number Data",
          "text-h5 red--text text-center"
          );
          this.$refs["Company_Telephone_Number_Add"].focus();
          return;
      }
      if(
          this.itemAddCom.code.trim() == "" ||
          this.itemAddCom.code.length > 6
        ){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
          true,
          "Failed",
          "Please Check Company Code Data",
          "text-h5 red--text text-center"
          );
          this.$refs["Company_Code_Add"].focus();
          return;
      }
      if(this.itemAddCom.inv_company_name.trim() == ""){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
          true,
          "Failed",
          "Please Check Company Name Data",
          "text-h5 red--text text-center"
          );
          this.$refs["INV_Company_Name_Add"].focus();
          return;
      }
      if(this.itemAddCom.inv_company_name_th.trim() == ""){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
          true,
          "Failed",
          "Please Check Company Name Thai Data",
          "text-h5 red--text text-center"
          );
          this.$refs["INV_Company_Name_TH_Add"].focus();
          return;
      }
      if(this.itemAddCom.inv_company_address.trim() == ""){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
          true,
          "Failed",
          "Please check the invoice information again.",
          "text-h5 red--text text-center"
          );
          this.$refs["Invoice_Address_Add"].focus();
          return;
      }
      if(this.itemAddCom.inv_company_branch.trim() == ""){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
          true,
          "Failed",
          "Please Check Branch Data",
          "text-h5 red--text text-center"
          );
          this.$refs["INV_Branch_Add"].focus();
          return;
      }
      if(
          this.itemAddCom.inv_company_email.trim() == "" ||
          !/^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.itemAddCom.inv_company_email.trim())
        ){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
          true,
          "Failed",
          "Please Check Company Email Data",
          "text-h5 red--text text-center"
          );
          this.$refs["INV_Email_Add"].focus();
          return;
      }else{
        const res_check_mail_inv_com = await api.getCompanyCol(this.itemAddCom.inv_company_email,`inv_company_email`)

        if(res_check_mail_inv_com.data.length > 0){
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
            true,
                "Failed",
                "Email Duplicated, Please Check Email",
                "text-h5 red--text text-center"
                );
            this.$refs[`INV_Email_Add`].focus();
            return;
        }
      }
      if(
        this.itemAddCom.inv_company_tel.trim() == "" ||
        !/^[+]?([0-9,-]+)$/.test(this.itemAddCom.inv_company_tel)
      ){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
          true,
          "Failed",
          "Please Check Telephone Number Data",
          "text-h5 red--text text-center"
          );
          this.$refs["INV_Telephone_Number_Add"].focus();
          return;
      }
      if(this.itemAddCom.inv_tax_id.trim() == ""){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
          true,
          "Failed",
          "Please Check Tax ID Data",
          "text-h5 red--text text-center"
          );
          this.$refs["INV_Tax_ID_Add"].focus();
          return;
      }

      const res_com = await api.getCompanyByComCode(this.itemAddCom.code.trim())
      if(res_com.data.length > 0){
          this.checkDialogRegister = 1;
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
          true,
          "Failed",
          "Company Code Duplicated, Please Check Company Code",
          "text-h5 red--text text-center"
          );
          this.$refs["Company_Code_Add"].focus();
          return;
      }
      if(this.itemAddCom.doc_status == 0){
        if(this.itemAddCom.running_len < 1){
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
            true,
            "Failed",
            "Please Check Range Data",
            "text-h5 red--text text-center"
            );
            this.$refs["Range"].focus();
            return;
        }
        if(this.itemAddCom.running_next  < 1){
          this.$store.state.global_dialog = true;
            this.setupAlertDialog(
            true,
            "Failed",
            "Please Check Next Running Data",
            "text-h5 red--text text-center"
            );
            this.$refs["Next Running"].focus();
            return;
        }
      }

      // console.log(this.itemAddCom)
      var item_data = {
          Firstname: this.Firstname.trim(),
          Lastname: this.Lastname.trim(),
          // Date_of_Birth:  this.Date_of_Birth,
          Email: this.Email.trim(),
          Username: this.Username.trim(),
          Password: this.Password.trim(),
          Confirm_Password: this.Confirm_Password.trim(),
          //company
          Company_Name: this.itemAddCom.name.trim(),
          Company_Address: this.itemAddCom.company_address.trim(),
          Company_Branch: this.itemAddCom.company_branch.trim(),
          Company_Email: this.itemAddCom.company_email.trim(),
          Company_Telephone_Number: this.itemAddCom.company_tel.trim(),
          Company_Code: this.itemAddCom.code.trim(),
          //INV
          INV_Company_Name: this.itemAddCom.inv_company_name.trim(),
          INV_Company_Name_TH: this.itemAddCom.inv_company_name_th.trim(),
          Invoice_Address: this.itemAddCom.inv_company_address.trim(),
          INV_Branch: this.itemAddCom.inv_company_branch.trim(),
          INV_Email: this.itemAddCom.inv_company_email.trim(),
          INV_Telephone_Number: this.itemAddCom.inv_company_tel.trim(),
          INV_Tax_ID: this.itemAddCom.inv_tax_id.trim(),
          module: "Employee",
          id_prefix: this.itemAddCom.id_prefix,
          running_year: this.itemAddCom.running_year, 
          running_len: this.itemAddCom.running_len,
          running_next: this.itemAddCom.running_next,
          doc_status: this.itemAddCom.doc_status,
      }
      this.$showLoader()
      const res_add_com = await api.addRegsiterCompany(item_data)
      if(res_add_com.status == 201){
          this.$hideLoader();
          this.dialogAddCom = false;
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
              true,
              "Information Saved",
              "Information successfully saved",
              "text-h5 green--text text-center"
          );
          return;
      }else{
          this.$hideLoader();
          this.dialogAddCom = false;
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
              true,
              "Information Saved",
              "Information Save failed",
              "text-h5 red--text text-center"
          );
          return;
      }
    },
    async savedataedit() {
      if (this.$refs.form.validate()) {
       
                if(this.itemedit.name.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed",
                    "Please Check Company Name Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["Company_Name"].focus();
                    return;
                }
                if(this.itemedit.company_address.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed",
                    "Please check the invoice information again.",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["Company_Address"].focus();
                    return;
                }
                if(this.itemedit.company_branch.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed",
                    "Please Check Branch Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["Company_Branch"].focus();
                    return;
                }
                if(this.itemedit.company_email.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed",
                    "Please Check Company Email Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["Company_Email"].focus();
                    return;
                }
                if(this.itemedit.company_tel.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed",
                    "Please Check Telephone Number Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["Company_Telephone_Number"].focus();
                    return;
                }

                if(this.itemedit.code.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed",
                    "Please Check Company Code Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["Company_Code"].focus();
                    return;
                }
                if(this.itemedit.inv_company_name.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed",
                    "Please Check Company Name Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["INV_Company_Name"].focus();
                    return;
                }
                if(this.itemedit.inv_company_name_th.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed",
                    "Please Check Company Name Thai Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["INV_Company_Name_TH"].focus();
                    return;
                }
                if(this.itemedit.inv_company_address.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed",
                    "Please check the invoice information again.",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["Invoice_Address"].focus();
                    return;
                }
                if(this.itemedit.inv_company_branch.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed",
                    "Please Check Branch Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["INV_Branch"].focus();
                    return;
                }
                if(this.itemedit.inv_company_email.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed",
                    "Please Check Company Email Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["INV_Email"].focus();
                    return;
                }
                if(this.itemedit.inv_company_tel.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed",
                    "Please Check Telephone Number Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["INV_Telephone_Number"].focus();
                    return;
                }
                if(this.itemedit.inv_tax_id.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed",
                    "Please Check Tax ID Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["INV_Tax_ID"].focus();
                    return;
                }

                if(this.itemdocedit.doc_status == 0){
                  if(this.itemdocedit.running_len < 1){
                      this.$store.state.global_dialog = true;
                      this.setupAlertDialog(
                      true,
                      "Failed",
                      "Please Check Range Data",
                      "text-h5 red--text text-center"
                      );
                      this.$refs["Range Edit"].focus();
                      return;
                  }
                  if(this.itemdocedit.running_next  < 1){
                    this.$store.state.global_dialog = true;
                      this.setupAlertDialog(
                      true,
                      "Failed",
                      "Please Check Next Running Data",
                      "text-h5 red--text text-center"
                      );
                      this.$refs["Next Running Edit"].focus();
                      return;
                  }
              }


      let formData = new FormData();
      formData.append("name", this.itemedit.name);
      formData.append("company_address", this.itemedit.company_address);
      formData.append("company_branch", this.itemedit.company_branch);
      formData.append("company_email", this.itemedit.company_email);
      formData.append("company_tel", this.itemedit.company_tel);
      formData.append("inv_company_name", this.itemedit.inv_company_name);
      formData.append("inv_company_name_th", this.itemedit.inv_company_name_th);
      formData.append("inv_company_address", this.itemedit.inv_company_address);
      formData.append("inv_company_branch", this.itemedit.inv_company_branch);
      formData.append("inv_company_email", this.itemedit.inv_company_email);
      formData.append("inv_company_tel", this.itemedit.inv_company_tel);
      formData.append("inv_tax_id", this.itemedit.inv_tax_id);
      formData.append("status", this.itemedit.status);

      if (this.itemedit.image != null || this.itemedit.image != "") {
        formData.append("image", this.itemedit.image);
      }

      // const res_update = await api.updateCompany(
      //   this.itemeditid,
      //   this.itemedit
      // );
      this.$showLoader()
      const res_update = await api.updateCompany(this.itemeditid, formData);
      const res_doc = await api.updateDocRunningCom(this.itemeditid,this.itemdocedit)
      this.$hideLoader()
      console.log(res_update);
      if (res_update.status == 200 || res_update.status == 201) {
        //   alert("debug1");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Success",
          "Update data success",
          "text-h5 green--text text-center"
        );
        this.getcompanydata();
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Update data Failed",
          "text-h5 red--text text-center"
        );
      }
      this.dialogedit = false;
    }
    },
    async checkSameComInvChangedAdd (value){
                if (value) {
                    this.itemAddCom.inv_company_name = this.itemAddCom.name.trim();
                    this.itemAddCom.inv_company_address = this.itemAddCom.company_address.trim();
                    this.itemAddCom.inv_company_branch = this.itemAddCom.company_branch.trim();
                    this.itemAddCom.inv_company_email = this.itemAddCom.company_email.trim();
                    this.itemAddCom.inv_company_tel = this.itemAddCom.company_tel.trim();
                }else{
                    this.itemAddCom.inv_company_name = "";
                    this.itemAddCom.inv_company_address = "";
                    this.itemAddCom.inv_company_branch = "";
                    this.itemAddCom.inv_company_email = "";
                    this.itemAddCom.inv_company_tel = "";
                }
            },
    async checkSameComInvChanged (value){
                if (value) {
                    this.itemedit.inv_company_name = this.itemedit.name.trim();
                    this.itemedit.inv_company_address = this.itemedit.company_address.trim();
                    this.itemedit.inv_company_branch = this.itemedit.company_branch.trim();
                    this.itemedit.inv_company_email = this.itemedit.company_email.trim();
                    this.itemedit.inv_company_tel = this.itemedit.company_tel.trim();
                }else{
                    this.itemedit.inv_company_name = "";
                    this.itemedit.inv_company_address = "";
                    this.itemedit.inv_company_branch = "";
                    this.itemedit.inv_company_email = "";
                    this.itemedit.inv_company_tel = "";
                }
            },
  },
  beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
};
