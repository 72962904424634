//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "@/services/constants";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import unity from "@/unity/unity";

export default {
    mounted() {
        // if (api.isLoggedIn()) {
        //     this.$router.push("/dashboard");
        // }
    
        this.$hideLoader();
    },
    components: { 
        SuccessDialog,
        SuccessDialogPush,
    },
    computed: {
        // computedDatePOFormatted() {
        //     return unity.formatDate(this.Date_of_Birth);
        // },
    },
    data() {
        return {
            text_color: "text-h5 green--text text-center",
            title: "green",
            message: "green",
            link: "",
            dialogAdd: false,
            dialogDecComCode: false,
            dialogRegister: false,
            dialogVerifyMail: false,
            checkDialogRegister: 0,
            opt_number: "",
            opt_number_check: "",
            //get value
            Firstname: "",
            Lastname: "",
            // Date_of_Birth:  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            // .toISOString()
            // .substr(0, 10),
            Email: "",
            Username: "",
            Password: "",
            Confirm_Password: "",

            //company
            Company_Name: "",
            Company_Address: "",
            Company_Branch: "",
            Company_Email: "",
            Company_Telephone_Number: "",
            Company_Code: "",

            //INV
            INV_Company_Name: "",
            INV_Company_Name_TH: "",
            Invoice_Address: "",
            INV_Branch: "",
            INV_Email: "",
            INV_Telephone_Number: "",
            INV_Tax_ID: "",
            
            checkSameComInv: false,

            //show hide password
            isShowPassword: false,
            isShowPassconfirmword: false,

            //date
            menu: false,
            //rules
            passwordRules: [
                (v) =>{

                    return (this.Confirm_Password.trim() != "" && v != this.Confirm_Password.trim()) ? "Please check that the Password and Confirm Password" : true;

                }
            ],
            confirmpasswordRules: [
                (v) =>{

                    return (this.Password.trim() != "" && v != this.Password.trim()) ? "Please check that the Password and Confirm Password" : true;

                }
            ],
            numberRules: [
            (v) =>
                /^[0-9]+$/.test(v) || "Please Input Number Only",
            ],
            telRules: [
            (v) =>
                /^[+]?([0-9]+)$/.test(v) || "Please Input Number Only",
            ],
            emailRules: [
            (v) =>
                /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                v
                ) || "Please enter a valid email address",
            ],
            comcodeRules: [
                (v) =>{
                    return v.length > 6 ? "Please check that the Company Code" : true;
                }
            ],
        };
    },
    watch: {
        checkSameComInv(newVal) {
         this.checkSameComInvChanged(newVal);
        }
    },
    methods: {
        async desComCode(){
            this.dialogDecComCode = true;
        },

        async checkMail(Email,Mode){

            if (
                !/^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    Email
                )
            ) {
                this.checkDialogRegister = 1;
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Check  E-mail Format",
                "text-h5 red--text text-center"
                );
                this.$refs[`${Mode}`].focus();
                return;
            }

        },
        async checkPass(){
            if(this.Password.trim() != this.Confirm_Password.trim()){
                this.checkDialogRegister = 1;
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Check Password and Confirm Password",
                "text-h5 red--text text-center"
                );
                this.$refs["Confirm_Password"].focus();
                return;
            }

        },
        async checkComCode(){
            if(this.Company_Code.trim().length > 6){
                this.checkDialogRegister = 1;
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Check Company Code",
                "text-h5 red--text text-center"
                );
                this.$refs["Company_Code"].focus();
                return;
            }else {
                const res_com = await api.getCompanyByComCode(this.Company_Code.trim())
                console.log(res_com.data)
                if(res_com.data.length > 0){
                    this.checkDialogRegister = 1;
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed",
                    "Company Code Duplicated, Please Check Company Code",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["Company_Code"].focus();
                    return;
                }

            }

        },
        async checkNumber(value,mode){
            if( !(/^([0-9]+)$/).test(value) ){
                this.checkDialogRegister = 1;
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Input Tax ID Number Only",
                "text-h5 red--text text-center"
                );
                this.$refs[`${mode}`].focus();
                return;
            }else if(mode == `INV_Tax_ID`){
                const res_taxid = await api.getCompanyByTaxId(value)
                console.log(res_taxid.data)
                if(res_taxid.data.length > 0){
                    this.checkDialogRegister = 1;
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed",
                    "Company Tax ID Duplicated, Please Check Tax ID",
                    "text-h5 red--text text-center"
                    );
                    this.$refs[`${mode}`].focus();
                    return;
                }
            }
        },
        async checkTel(value,mode){
            if( !(/^[+]?([0-9]+)$/).test(value) ){
                this.checkDialogRegister = 1;
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Input Telephone Number is Number Only",
                "text-h5 red--text text-center"
                );
                this.$refs[`${mode}`].focus();
                return;
            }
        },
        async checkPass(){
            if(this.Password.trim() != this.Confirm_Password.trim()){
                this.checkDialogRegister = 1;
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Check Password and Confirm Password",
                "text-h5 red--text text-center"
                );
                this.$refs["Confirm_Password"].focus();
                return;
            }

        },
        async checkSameComInvChanged (value){
            if (value) {
                this.INV_Company_Name = this.Company_Name.trim();
                this.Invoice_Address = this.Company_Address.trim();
                this.INV_Branch = this.Company_Branch.trim();
                this.INV_Email = this.Company_Email.trim();
                this.INV_Telephone_Number = this.Company_Telephone_Number.trim();
            }else{
                this.INV_Company_Name = "";
                this.Invoice_Address = "";
                this.INV_Branch = "";
                this.INV_Email = "";
                this.INV_Telephone_Number = "";
            }
        },
        async checkMailDup(email,mode,mode_focus){
            const res_check_mail_col = await api.getCompanyCol(email,mode)
            console.log(res_check_mail_col.data)
            if(res_check_mail_col.data.length > 0){
                this.checkDialogRegister = 1;
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                    "Failed",
                    "Email Duplicated, Please Check Email",
                    "text-h5 red--text text-center"
                    );
                this.$refs[`${mode_focus}`].focus();
                return;
            }
        },
        async openDialogRegister(){
            if(this.Firstname.trim() == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Check Firstname Data",
                "text-h5 red--text text-center"
                );
                this.$refs["Firstname"].focus();
                return;
            }
            if(this.Lastname.trim() == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Check Lastname Data",
                "text-h5 red--text text-center"
                );
                this.$refs["Lastname"].focus();
                return;
            }
            if(this.Email.trim() == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Check Email Data",
                "text-h5 red--text text-center"
                );
                this.$refs["Email"].focus();
                return;
            }
            if(this.Username.trim() == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Check Username Data",
                "text-h5 red--text text-center"
                );
                this.$refs["Username"].focus();
                return;
            }
            if(this.Password.trim() == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Check Password Data",
                "text-h5 red--text text-center"
                );
                this.$refs["Password"].focus();
                return;
            }
            if(this.Confirm_Password.trim() == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Check Confirm Password Data",
                "text-h5 red--text text-center"
                );
                this.$refs["Confirm_Password"].focus();
                return;
            }
            if(this.Company_Name.trim() == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Check Company Name Data",
                "text-h5 red--text text-center"
                );
                this.$refs["Company_Name"].focus();
                return;
            }
            if(this.Company_Address.trim() == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please check the invoice information again.",
                "text-h5 red--text text-center"
                );
                this.$refs["Company_Address"].focus();
                return;
            }
            if(this.Company_Branch.trim() == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Check Branch Data",
                "text-h5 red--text text-center"
                );
                this.$refs["Company_Branch"].focus();
                return;
            }
            if(this.Company_Email.trim() == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Check Company Email Data",
                "text-h5 red--text text-center"
                );
                this.$refs["Company_Email"].focus();
                return;
            }
            if(this.Company_Telephone_Number.trim() == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Check Telephone Number Data",
                "text-h5 red--text text-center"
                );
                this.$refs["Company_Telephone_Number"].focus();
                return;
            }

            if(this.Company_Code.trim() == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Check Company Code Data",
                "text-h5 red--text text-center"
                );
                this.$refs["Company_Code"].focus();
                return;
            }
            if(this.INV_Company_Name.trim() == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Check Company Name Data",
                "text-h5 red--text text-center"
                );
                this.$refs["INV_Company_Name"].focus();
                return;
            }
            if(this.INV_Company_Name_TH.trim() == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Check Company Name Thai Data",
                "text-h5 red--text text-center"
                );
                this.$refs["INV_Company_Name_TH"].focus();
                return;
            }
            if(this.Invoice_Address.trim() == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please check the invoice information again.",
                "text-h5 red--text text-center"
                );
                this.$refs["Invoice_Address"].focus();
                return;
            }
            if(this.INV_Branch.trim() == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Check Branch Data",
                "text-h5 red--text text-center"
                );
                this.$refs["INV_Branch"].focus();
                return;
            }
            if(this.INV_Email.trim() == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Check Company Email Data",
                "text-h5 red--text text-center"
                );
                this.$refs["INV_Email"].focus();
                return;
            }
            if(this.INV_Telephone_Number.trim() == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Check Telephone Number Data",
                "text-h5 red--text text-center"
                );
                this.$refs["INV_Telephone_Number"].focus();
                return;
            }
            if(this.INV_Tax_ID.trim() == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Check Tax ID Data",
                "text-h5 red--text text-center"
                );
                this.$refs["INV_Tax_ID"].focus();
                return;
            }
            this.checkDialogRegister = 0;
            await this.checkMail(this.Email.trim(),`Email`)
            await this.checkPass()
            await this.checkMail(this.Company_Email.trim(),`Company_Email`)
            await this.checkComCode()
            await this.checkMail(this.INV_Email.trim(),`INV_Email`)
            await this.checkNumber(this.INV_Tax_ID.trim(), `INV_Tax_ID`)
            await this.checkTel(this.Company_Telephone_Number.trim(), `Company_Telephone_Number`)
            await this.checkTel(this.INV_Telephone_Number.trim(), `INV_Telephone_Number`)
            await this.checkMailDup(this.Company_Email.trim(), `company_email`,`Company_Email`);
            await this.checkMailDup(this.INV_Email.trim(), `inv_company_email`,`INV_Email`);

            if(this.checkDialogRegister == 0){
                this.dialogRegister = true;
            }
        },
        async closeDialogRegister(){
            this.dialogRegister = false;
        },
        async openVerifyEmail(){
            this.dialogRegister = false;
            this.$showLoader()
            var res_check_mail = await api.checkVerifyEmail({ email: this.Email})
            this.$hideLoader();
            // console.log(res_check_mail.datamessage)
            if(res_check_mail.data.message == "Have User"){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Failed",
                    "Please check the email, This email is  registered",
                    "text-h5 red--text text-center"
                );
                return;
            }else{
                this.opt_number_check = res_check_mail.data.message;
                this.dialogVerifyMail = true;
            }

        },
        async verifyEmail(){
            if(this.opt_number.trim() == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Failed",
                "Please Check OTP Number Data",
                "text-h5 red--text text-center"
                );
                this.$refs["OTP"].focus();
                return;
            }
           
            if(this.opt_number_check == this.opt_number){
                this.dialogVerifyMail = false;
                await this.saveRegister()
            }else{
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Failed",
                    "OTP Number not Match",
                    "text-h5 red--text text-center"
                );
                return;
            }

        },
        async sendVerifyEmail(){
            this.$showLoader()
                var res_send_check_mail = await api.checkVerifyEmail({ email: this.Email})
                this.opt_number_check = res_send_check_mail.data.message;
            this.$hideLoader();
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
                true,
                "Check Email",
                "Plase Check Your Email",
                "text-h5 green--text text-center"
            );
            return;
        },
        async closeVerifyEmail(){
            this.dialogVerifyMail = false;
        },
        async saveRegister(){
            this.dialogRegister = false;
            var item_data = {
                Firstname: this.Firstname.trim(),
                Lastname: this.Lastname.trim(),
                // Date_of_Birth:  this.Date_of_Birth,
                Email: this.Email.trim(),
                Username: this.Username.trim(),
                Password: this.Password.trim(),
                Confirm_Password: this.Confirm_Password.trim(),

                //company
                Company_Name: this.Company_Name.trim(),
                Company_Address: this.Company_Address.trim(),
                Company_Branch: this.Company_Branch.trim(),
                Company_Email: this.Company_Email.trim(),
                Company_Telephone_Number: this.Company_Telephone_Number.trim(),
                Company_Code: this.Company_Code.trim(),

                //INV
                INV_Company_Name: this.INV_Company_Name.trim(),
                INV_Company_Name_TH: this.INV_Company_Name_TH.trim(),
                Invoice_Address: this.Invoice_Address.trim(),
                INV_Branch: this.INV_Branch.trim(),
                INV_Email: this.INV_Email.trim(),
                INV_Telephone_Number: this.INV_Telephone_Number.trim(),
                INV_Tax_ID: this.INV_Tax_ID.trim(),

            }
            this.$showLoader()
            const res_add_com = await api.addRegsiterCompany(item_data)
            if(res_add_com.status == 201){
                this.$hideLoader();
                this.$store.state.global_push_dialog = true;
                this.setupAlertDialogPush(
                true,
                "Information Saved",
                "Information successfully saved",
                "text-h5 green--text text-center",
                `/login`
                );
            }else{
                this.$hideLoader();
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Information Saved",
                    "Information Save failed",
                    "text-h5 red--text text-center"
                );
                return;
            }
        },

        setupAlertDialog(status, title, message, text_color) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
        },
        setupAlertDialogPush(status, title, message, text_color, link) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
            this.link = link;
        },
    },
};
