//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "@/services/constants";
import StockCard from "@/components/cards/StockCard";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "../computing/tolocalestringnumber";
import unity from "@/unity/unity";

export default {
  name: "New Activity",
  data() {
    return {
      dialogAdd: false,
      dialogSendMail: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      Emp_list: [],
      jobtitle_list: [],
      charge_list: [
        { value: "T/C", name: "Time Charge" },
        { value: "N/C", name: "No Charge" },
        { value: "F/C", name: "Fix Charge" },
        { value: "OFF", name: "ทำงานออฟฟิต" },
      ],
      customer_list: [],
      menuDateCheckin: false,
      menuDateCheckout: false,
      dataItem: {
        userId: Number(localStorage.getItem(server.USER_ID)),
        customer_id: 0,
        jobtitle_id: 0,
        // checkin: "",
        // checkout: "",
        checkin: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        checkout: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        timecheckin: "",
        timecheckout: "",
        timecheckinadd: "",
        timecheckoutadd: "",
        hours: "",
        chargetype: "",
        prospect: "",
        detail: "",
        durationhour: 0,
        durationminute: 0,
      },
      statusActivity: "",
      checklate: false,
      update_id: 0
    };
  },
  components: {
    StockCard,
    SuccessDialog,
    SuccessDialogPush,
  },
  computed: {
    computedDateCheckInFormatted() {
      return unity.formatDate(this.dataItem.checkin);
    },
    computedDateCheckOutFormatted() {
      return unity.formatDate(this.dataItem.checkout);
    },
  },

  watch: {},

  async mounted() {
    await api.checkVersion();

    this.$forceUpdate();
    const userId = localStorage.getItem(server.USER_ID);
    if (userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
    this.loadEmp();
    this.loadCustomer();
    this.loadJobtitle();
    this.loadActivity();
    this.$hideLoader();
  },
  methods: {
    async loadEmp() {
      const result = await api.getAccountslist_by_ComID(
        localStorage.getItem(server.COMPANYID)
      );

      this.Emp_list = result.data;
    },
    async loadCustomer() {
      const result = await api.getAllCustomerListactiveCompany(
        { 
          params: {
            company_id: localStorage.getItem(server.COMPANYID),
          },
        }
      );
      this.customer_list = result.data;
    },
    async loadJobtitle() {
      const result = await api.getAllJobtitleByCompany(
        localStorage.getItem(server.COMPANYID)
      );

      this.jobtitle_list = result.data;
    },
    async loadActivity(){
      const resCheckInData = await api.getActivityByID(Number(this.$route.params.id))
      console.log("resCheckInData:")
      console.log(resCheckInData.data)
      
      this.dataItem.timecheckin = `${resCheckInData.data[0].checkInDate_time}:00`;

      const checkinTime = new Date(`1970-01-01T${this.dataItem.timecheckin}`); // ใช้วันที่มาตรฐาน

      // ตั้งเวลาในเวลาท้องถิ่น
      const thresholdTime = new Date();
      thresholdTime.setHours(10, 0, 0, 0);  // ตั้งเวลาของวันที่ปัจจุบัน
      // เปรียบเทียบเวลา
      if (
        checkinTime.getHours() > thresholdTime.getHours() || 
        (checkinTime.getHours() === thresholdTime.getHours() && checkinTime.getMinutes() > thresholdTime.getMinutes()) || 
        (checkinTime.getHours() === thresholdTime.getHours() && checkinTime.getMinutes() === thresholdTime.getMinutes() && checkinTime.getSeconds() > thresholdTime.getSeconds())
      ) {
        this.checklate = true; // เช็คอินหลัง 
      } else {
        this.checklate = false; // เช็คอินก่อน 
      }

      this.dataItem.timecheckout = `${resCheckInData.data[0].checkOutDate_time}:00`;
      this.dataItem.chargetype = resCheckInData.data[0].chargeType;
      this.dataItem.jobtitle_id = Number(resCheckInData.data[0].jobTitleId);
      this.dataItem.customer_id = Number(resCheckInData.data[0].customerId);
      this.dataItem.prospect = resCheckInData.data[0].prospect;
      this.dataItem.detail = resCheckInData.data[0].detail;
      
    },
    async getWorkHourDuration() {
      if (
        this.dataItem.checkin != "" &&
        this.dataItem.timecheckin != "" &&
        this.dataItem.checkout != "" &&
        this.dataItem.timecheckout != ""
      ) {
        let startDate =
          this.dataItem.checkin +
          " " +
          this.dataItem.timecheckin.split("T")[1].split(".000")[0];
        let endDate =
          this.dataItem.checkout +
          " " +
          this.dataItem.timecheckout.split("T")[1].split(".000")[0];
        startDate = new Date(startDate);
        endDate = new Date(endDate);
        let days = parseInt((endDate - startDate) / (1000 * 60 * 60 * 24));
        let hours = parseInt(
          (Math.abs(endDate - startDate) / (1000 * 60 * 60)) % 24
        );
        if (hours > 4) {
          hours -= 1;
        }
        let minutes = parseInt(
          (Math.abs(endDate.getTime() - startDate.getTime()) / (1000 * 60)) % 60
        );
        // console.log("day: " + days)
        // console.log("hour: " + hours + "min" + minutes)
        this.dataItem.durationhour = days * 8 + hours;
        this.dataItem.durationminute = minutes;
      }
    },
    async save() {
      var dataUpdate = {}
      dataUpdate.chargeType = this.dataItem.chargetype;
      dataUpdate.jobTitleId = this.dataItem.jobtitle_id;
      dataUpdate.customerId = this.dataItem.customer_id;
      dataUpdate.prospect = this.dataItem.prospect;
      dataUpdate.detail = this.dataItem.detail.replace(/'/g, "\\'");
      dataUpdate.addby = "W";
      
      this.$showLoader();
      const res_updateAct = await api.updateActivityEdit(Number(this.$route.params.id),dataUpdate);
      this.$hideLoader()
      if (res_updateAct.status == 200 || res_updateAct.status == 201) {
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "Information Saved",
          "Information successfully saved",
          "text-h5 green--text text-center",
          "/my-activity"
        );
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้",
          "text-h5 red--text text-center"
        );
        return;
      }
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
  },
  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
  async created() {},
};
