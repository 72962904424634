import httpClient from "@/services/httpClient";
import {
  server
} from "@/services/constants";

export const createActivity = (data) => {
  return httpClient.post(server.ACTIVITY +`/createFWeb`,data);
};
export const updateActivity = (id,data) => {
  return httpClient.put(server.ACTIVITY +`/updateFWeb/${id}`,data);
};
export const updateActivityEdit = (id,data) => {
  return httpClient.put(server.ACTIVITY +`/updateFWebEdit/${id}`,data);
};
export const getActivityAll = () => {
  return httpClient.get(server.ACTIVITY + `/get/list`);
};
export const getActivityAllByCompany = (company_id) => {
  return httpClient.get(server.ACTIVITY + `/getByConpany/list/${company_id}`);
};
export const getAllActivityByUserID = (userid) => {
  return httpClient.get(server.ACTIVITY + `/getByConpany-list-by-userid/${userid}`);
};
export const getCheckinDateWeb = (userid) => {
  return httpClient.get(server.ACTIVITY + `/checkusercheckin/${userid}`);
};
export const getActivityByID = (id) => {
  return httpClient.get(server.ACTIVITY + `/getActivityByID/${id}`);
};
export const getAllActivityByDep = (depid) => {
  return httpClient.get(server.ACTIVITY + `/getByConpany-list-by-dep/${depid}`);
};

export const getActivityfortimesheetreport = (query) => {
  return httpClient.get(server.ACTIVITY + `/getfortimesheetreport`, query);
};
export const getfortimesheetAttendantreport = (query) => {
  return httpClient.get(server.ACTIVITY + `/getfortimesheetAttendantreport`, query);
};

export const getActivityforemployeesummaryreport = (query) => {
  return httpClient.get(server.ACTIVITY + `/getforemployeesummaryreport`, query);
};

export const getActivityforclientsummaryreport = (query) => {
  return httpClient.get(server.ACTIVITY + `/getforclientsummaryreport`, query);
};

export const getActivityattendantreport = (query) => {
  return httpClient.get(server.ACTIVITY + `/getattendantreport`, query);
};

export const getcheckattendantuserid = (query) => {
  return httpClient.get(server.ACTIVITY + `/checkattendantuserid`, query);
};

export const getdataReportSumAct = (query) => {
  return httpClient.get(server.ACTIVITY + `/summary-timesheets-activity-report`, query);
};
export const deleteActivity = (id) => {
  return httpClient.delete(server.ACTIVITY + `/${id}`);
};