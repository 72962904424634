//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "@/services/constants";
import { language } from "../language/Advancelist.js";
import { tolocalestringnumber } from "../computing/tolocalestringnumber";
import unity from "@/unity/unity";

export default {
  name: "advance-list",
  data(vm) {
    return {
      search_status: "",
      menu: false,
      menu2: false,
      search: "",
      toggleEnable: false,
      selectstatus: "",
      dataAll: [],
      status: ["Draft", "Request", "Approve", "Reject"],
      datefrom: null,
      dateto: null,
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      seticonsearchmore: { icon: "mdi mdi-chevron-down", action: false },
      setlanguage: language.en,
      defaultlanguage: "en",
      // regexlang: "th",
      chooselanguage: [
        { name: "en", flag: "gb" },
        { name: "th", flag: "th" },
      ],
      aa: "สวัสดี",
      dialogEdit: false,
      dialogDelete: false,
      mDataArray: [],
      position: [],
      approver: [],
      department: [],
      department_list: [],
      position_list: [],
      company: [],
      authorize: [],
      // mDataArray: [
      //   {
      //     no: 1,
      //     date: "1/11/2022",
      //     name: "testname",
      //     customer: "ABS co Ltd",
      //     amount: "10,000",
      //     status: "Unapproved",
      //   },
      //   {
      //     no: 2,
      //     date: "2/11/2022",
      //     name: "testname2",
      //     customer: "ABS co Ltd",
      //     amount: "3,000",
      //     status: "Approved",
      //   },
      // ],
      headers: [
          {
              text: "Name",
              value: "name",
              class: "bg-colorth tabletextwhite",
              divider: true,
              width: "10%",
              width: "120px"
          },
          {
              text: " Job Title/Case ",
              value: "job_name",
              class: "bg-colorth tabletextwhite",
              divider: true,
              width: "140px"
          },
          {
              text: "Customer",
              value: "customer_name",
              class: "bg-colorth tabletextwhite",
              divider: true,
              width: "150px"
          },
          {
              text: "Check-In",
              value: "checkInDate_show",
              class: "bg-colorth tabletextwhite",
              divider: true,
              width: "150px",
              align: "center"

          },

          {
              text: "Check-Out",
              value: "checkOutDate_show",
              class: "bg-colorth tabletextwhite",
              divider: true,
              width: "150px" ,
              align: "center"
          },
          {
              text: "Hour",
              value: "duration",
              class: "bg-colorth tabletextwhite",
              divider: true,
              width: "80px",
              align: "end"
          },
          {
              text: "Charge Type",
              value: "chargeType",
              class: "bg-colorth tabletextwhite",
              divider: true,
              width: "120px",
              align: "center"
          },
          {
              text: "Prospect",
              value: "prospect",
              class: "bg-colorth tabletextwhite",
              divider: true,
              width: "100px",
          },
          {
              text: "Detail",
              value: "detail",
              class: "bg-colorth tabletextwhite",
              divider: true,
              width: "200px",
          },
          {
              text: "",
              value: "actions",
              class: "bg-colorth tabletextwhite",
              divider: true,
              width: "110px",
              align: "center"
          },
      ],
      charge_list: [
      { value: "T/C", name: "Time Charge" },
      { value: "N/C", name: "No Charge" },
      { value: "F/C", name: "Fix Charge" },
      { value: "OFF", name: "ทำงานออฟฟิต" },
    ],
      jobTitle_list: [],
      searchCharge: null,
      searchChargeJob: null,
      searchEmp: [],
      delete_id: 0,
      dialogDeleteActivity: false
    };
  },

  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
  async created() {
    // this.initialize();
  },
  beforeUpdate() {},
  computed: {
    computeddatefromFormatted() {
      return unity.formatDate(this.datefrom);
    },
    computeddateToFormatted() {
      return unity.formatDate(this.dateto);
    },
    itemsForSelected() {
      if (this.search.length) {
        return this.dataSource.filter((item) => this.search.includes(item));
      }
      return this.dataSource;
    },
  },
  async mounted() {
    await api.checkVersion();
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);

    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed",
        "Please Logout And Login Again",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(this.userId, router_path);

    // console.log("res_auth:" + JSON.stringify(res_auth.data));

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    // console.log("res_auth:" + JSON.stringify(res_auth.data));
    // console.log("authorize_view:" + this.authorize_view);
    // console.log("authorize_add:" + this.authorize_add);
    // console.log("authorize_edit:" + this.authorize_edit);
    // console.log("authorize_del:" + this.authorize_del);
    // this.$router.back();

       if (!this.authorize_view) {
      this.$router.push("/not-found");
    }

    // ----------------- Check Authorize ---------------------------

    let comp_id = localStorage.getItem(server.COMPANYID);
    // await this.loadCompanyMaster(comp_id);
    // await this.loadDepartmentMaster(comp_id);
    // await this.loadPositionMaster(comp_id);

    await this.loadAuthorize();
    await this.loaddata();

    this.userId = localStorage.getItem(server.USER_ID);
    if (this.userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
  },
  methods: {
    editItem() {},
    changelange: function(newVal, oldVal) {},
    showsearchmore() {
      this.toggleEnable = !this.toggleEnable;
      this.seticonsearchmore.action = !this.seticonsearchmore.action;
      if (this.seticonsearchmore.action === true) {
        this.seticonsearchmore.icon = "mdi mdi-chevron-up";
      } else {
        this.seticonsearchmore.icon = "mdi mdi-chevron-down";
      }
    },
    finddatafromdate() {
    this.searchStu_date = 1;
    this.mDataArray = this.dataAll.filter((item) => {
      if (this.searchCharge == null) {
        if (this.searchChargeJob == null) {
          if (this.datefrom != null && this.dateto != null) {
            if (
              item.checkInDate_S >= this.datefrom &&
              item.checkOutDate_S <= this.dateto
            ) {
              return item;
            }
          }
          if (this.datefrom == null && this.dateto == null) {
            return item;
          }
        } else {
          if (this.datefrom != null && this.dateto != null) {
            if (
              item.checkInDate_S >= this.datefrom &&
              item.checkOutDate_S <= this.dateto &&
              item.job_name == this.searchChargeJob
            ) {
              return item;
            }
          }
          if (this.datefrom == null && this.dateto == null) {
            if (item.job_name == this.searchChargeJob) {
              return item;
            }
          }
        }
      } else {
        if (this.searchChargeJob == null) {
          if (this.datefrom != null && this.dateto != null) {
            if (
              item.checkInDate_S >= this.datefrom &&
              item.checkOutDate_S <= this.dateto &&
              item.chargeType == this.searchCharge
            ) {
              return item;
            }
          }
          if (this.datefrom == null && this.dateto == null) {
            if (item.chargeType == this.searchCharge) {
              return item;
            }
          }
        } else {
          if (this.datefrom != null && this.dateto != null) {
            if (
              item.checkInDate_S >= this.datefrom &&
              item.checkOutDate_S <= this.dateto &&
              item.chargeType == this.searchCharge &&
              item.job_name == this.searchChargeJob
            ) {
              return item;
            }
          }
          if (this.datefrom == null && this.dateto == null) {
            if (
              item.chargeType == this.searchCharge &&
              item.job_name == this.searchChargeJob
            ) {
              return item;
            }
          }
        }
      }
    });
  },

    findChaege() {
    if(this.searchEmp.length > 0){
      this.mDataArray = this.dataAll.filter((item) => {
        if (this.datefrom == null || this.dateto == null) {
          if (this.searchCharge != null) {
            if (this.searchChargeJob != null) {
              if (
                item.chargeType == this.searchCharge &&
                item.job_name == this.searchChargeJob
              ) {
                for(let i=0;i<this.searchEmp.length;i++){
                  if(this.searchEmp[i] == Number(item.userId)){
                    return item;
                  }
                }   
              }
            } else {
              if (item.chargeType == this.searchCharge) {
                for(let i=0;i<this.searchEmp.length;i++){
                  if(this.searchEmp[i] == Number(item.userId)){
                    return item;
                  }
                }
              }
            }
          } else {
            if (this.searchChargeJob != null) {
              if (item.job_name == this.searchChargeJob) {
                for(let i=0;i<this.searchEmp.length;i++){
                  if(this.searchEmp[i] == Number(item.userId)){
                    return item;
                  }
                }
              }
            } else {
              for(let i=0;i<this.searchEmp.length;i++){
                if(this.searchEmp[i] == Number(item.userId)){
                  return item;
                }
              }
            }
          }
        }
        if (this.datefrom != null && this.dateto != null) {
          if (this.searchCharge != null) {
            if (this.searchChargeJob != null) {
              if (
                item.chargeType == this.searchCharge &&
                item.checkInDate_S >= this.datefrom &&
                item.checkOutDate_S <= this.dateto &&
                item.job_name == this.searchChargeJob
              ) {
                for(let i=0;i<this.searchEmp.length;i++){
                  if(this.searchEmp[i] == Number(item.userId)){
                    return item;
                  }
                }
              }
            } else {
              if (
                item.chargeType == this.searchCharge &&
                item.checkInDate_S >= this.datefrom &&
                item.checkOutDate_S <= this.dateto
              ) {
                for(let i=0;i<this.searchEmp.length;i++){
                  if(this.searchEmp[i] == Number(item.userId)){
                    return item;
                  }
                }
              }
            }
          } else {
            if (this.searchChargeJob != null) {
              if (
                item.checkInDate_S >= this.datefrom &&
                item.checkOutDate_S <= this.dateto &&
                item.job_name == this.searchChargeJob
              ) {
                for(let i=0;i<this.searchEmp.length;i++){
                  if(this.searchEmp[i] == Number(item.userId)){
                    return item;
                  }
                }
              }
            } else {
              if (
                item.checkInDate_S >= this.datefrom &&
                item.checkOutDate_S <= this.dateto
              ) {
                for(let i=0;i<this.searchEmp.length;i++){
                  if(this.searchEmp[i] == Number(item.userId)){
                    return item;
                  }
                }
              }
            }
          }
        }
      });

    }else{
      this.mDataArray = this.dataAll.filter((item) => {
        if (this.datefrom == null || this.dateto == null) {
          if (this.searchCharge != null) {
            if (this.searchChargeJob != null) {
              if (
                item.chargeType == this.searchCharge &&
                item.job_name == this.searchChargeJob
              ) {
                return item;
              }
            } else {
              if (item.chargeType == this.searchCharge) {
                return item;
              }
            }
          } else {
            if (this.searchChargeJob != null) {
              if (item.job_name == this.searchChargeJob) {
                return item;
              }
            } else {
              return item;
            }
          }
        }
        if (this.datefrom != null && this.dateto != null) {
          if (this.searchCharge != null) {
            if (this.searchChargeJob != null) {
              if (
                item.chargeType == this.searchCharge &&
                item.checkInDate_S >= this.datefrom &&
                item.checkOutDate_S <= this.dateto &&
                item.job_name == this.searchChargeJob
              ) {
                return item;
              }
            } else {
              if (
                item.chargeType == this.searchCharge &&
                item.checkInDate_S >= this.datefrom &&
                item.checkOutDate_S <= this.dateto
              ) {
                return item;
              }
            }
          } else {
            if (this.searchChargeJob != null) {
              if (
                item.checkInDate_S >= this.datefrom &&
                item.checkOutDate_S <= this.dateto &&
                item.job_name == this.searchChargeJob
              ) {
                return item;
              }
            } else {
              if (
                item.checkInDate_S >= this.datefrom &&
                item.checkOutDate_S <= this.dateto
              ) {
                return item;
              }
            }
          }
        }
      });
    }
  },
    async loadAuthorize() {
      const res_get = await api.getSettingGroupMenu();
      // console.log("res loadAuthorize : ", JSON.stringify(res_get));
      this.authorize = res_get.data;
    },

    async loaddata() {
      const res_joblist = await api.getAllJobtitleByCompany(localStorage.getItem(server.COMPANYID));
      this.jobTitle_list = res_joblist.data;

      const resData = await api.getAllActivityByUserID(localStorage.getItem(server.USER_ID))
      this.dataAll = resData.data;
      this.mDataArray = resData.data;
      this.$hideLoader();
    },

    openeditItem(id){
      this.$router.push(`/edit-activity/${id}`);
    },

    deleteItem(id){
      this.delete_id = id;
      this.dialogDeleteActivity = true;
    },

    async deleteItemConfirm(){
      this.$showLoader();
          
      const delete_activity = await api.deleteActivity(this.delete_id)
      
      if (delete_activity.status == 200 || delete_activity.status == 201) {
          this.dialogDeleteActivity = false;
          await this.loadAuthorize();
          await this.loaddata();
          this.$hideLoader()
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialog(
              true,
              "Successfully  Deleted",
              "The activity has been successfully deleted.",
              "text-h5 green--text text-center"
          );
          return;
      } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
              true,
              "ลบข้อมูล",
              "ไม่สามารถลบได้",
              "text-h5 red--text text-center"
          );
      }
    },

    closeDelete(){
      this.delete_id = 0;
      this.dialogDeleteActivity = false;
    }
  },
};
