import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getDocRunningByCompany = (company_id) => {
    return httpClient.get(server.DOC_RUNNING + `/get-by-company/${company_id}`);
};
export const fineDocRunningByModlue = (module_name,company_id) => {
    return httpClient.get(server.DOC_RUNNING + `/get-by-modlue/${module_name}/${company_id}`);
};
export const checkDocRunning = (module_name,company_id) => {
    return httpClient.get(server.DOC_RUNNING + `/check-docrunning/${module_name}/${company_id}`);
};

export const updateDocRunning = (id, data) => {
    return httpClient.put(server.DOC_RUNNING + `/${id}`, data);
};
export const updateDocRunningCom = (id, data) => {
    return httpClient.put(server.DOC_RUNNING + `/doc-com/${id}`, data);
};
export const updateDocRunningAll = (data) => {
    return httpClient.put(server.DOC_RUNNING + `/update-all`, data);
};