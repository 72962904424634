//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import SuccessDialog from "@/components/cards/SuccessDialog";
    import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
    import api from "@/services/api";
    import { server } from "@/services/constants";

export default {
    name: "Approver Management Setting",
    data() {
    return {
        dialogAdd: false,
        text_color: "text-h5 green--text text-center",
        title: "green",
        message: "green",
        link: "",
        emp_list: [],
        modlue:"",
        statusAM: "",
        approvals: [{ selected: null }],
        statusOptions: ['Active', 'Inactive'], 
    };
    },
    components: {
    SuccessDialog,
    SuccessDialogPush,
    },
    computed: {},
    watch: {},
    async mounted() {
    const userId = localStorage.getItem(server.USER_ID);
    if (userId && api.isLoggedIn()) {
        // this.$router.push("/");
    } else {
        this.$store.state.isLogged = false;
        this.$router.push("/login");
    }
    await this.loadEmp()
    await this.loaddata()

    this.$hideLoader();
    },
    methods: {
        async loaddata(){
            const resultData = await api.getApproverManagementByID(this.$route.params.id);
            this.modlue = resultData.data[0].module;
            this.statusAM = resultData.data[0].status;

            // แยก approvals_id จากสตริงที่คั่นด้วยจุลภาค และเก็บค่าเข้าไปใน this.approvals
            const approvals_ids = resultData.data[0].approver_id;

            if (approvals_ids) { // ถ้ามีค่า approvals_id
            this.approvals = approvals_ids
                .split(',')  // แยก string โดยคั่นด้วยเครื่องหมายจุลภาค
                .map(id => ({ selected: Number(id.trim()) }));  // ลบช่องว่าง และแปลงเป็น number
            } else {
            this.approvals = [{ selected: null }];  // ถ้าไม่มีค่า ให้เป็น null
            }

        },
        async loadEmp() {
            const result = await api.getAccountslist_by_ComID(
                localStorage.getItem(server.COMPANYID)
            );

            this.emp_list = result.data;
        },
        addAutocomplete() {
            this.approvals.push({ selected: null }); // เพิ่ม Autocomplete ใหม่
        },
        removeAutocomplete(index) {
            this.approvals.splice(index, 1); // ลบ Autocomplete
        },
        async saveItem() {

            const approvals_id = this.approvals.length > 1
            ? this.approvals.map(item => item.selected).join(',')
            : this.approvals[0]?.selected || ''; // ถ้ามีแค่ 1 ตัวให้แสดงค่า id เดียว

            var dataUpdate = {
                approver_id: approvals_id,
                status: this.statusAM
            }

            this.$showLoader()
            const res_update = await api.updateApproverManagement(this.$route.params.id, dataUpdate)
            if(res_update.status == 200 || res_update.status == 201){
                this.$hideLoader();
                this.$store.state.global_push_dialog = true;
                this.setupAlertDialogPush(
                    true,
                    "Information Saved",
                    "Information successfully saved",
                    "text-h5 green--text text-center",
                    "/approver-management"
                );
            }else{
                this.$hideLoader();
                this.setupAlertDialog(
                    true,
                    "Information Saved",
                    "ไม่สามารถบันทึกได้",
                    "text-h5 red--text text-center"
                );
                return;
            }

        },
        setupAlertDialog(status, title, message, text_color) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
        },
        setupAlertDialogPush(status, title, message, text_color, link) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
            this.link = link;
        },
        },
        async beforeCreate() {
            console.log("beforeCreate");
            this.$store.state.navMenu = true;
        },
    };
