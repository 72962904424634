import httpClient from "@/services/httpClient";
import {
    server
} from "@/services/constants";

export const getApproverManagementByCompany = (company_id) => {
    return httpClient.get(server.Approver_Management + `/${company_id}`);
};
export const getApproverManagementByID = (id) => {
    return httpClient.get(server.Approver_Management + `/by-id/${id}`);
};
export const getApproverManagementModule = (modlue) => {
    return httpClient.get(server.Approver_Management + `/by-modlue/${modlue}`);
};

export const updateApproverManagement = (id, data) => {
    return httpClient.put(server.Approver_Management + `/${id}`, data);
};