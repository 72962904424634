//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "@/services/constants";
export default {
  data: () => ({
    vat_type_list:[{id:"I",name:"Include Vat"},{id:"E",name:"Exclude Vat"}],
    prename_t_h: ["นาย", "นาง", "นางสาว"],
    prename_e_n: ["Mr", "Miss", "Mrs."],
    dialog: false,
    dialogDelete: false,
    search: "",
    check_coppy: false,
    disabled_copy: false,
    headers: [
      { text: "Code", value: "cust_account" },
      { text: "Customer Name", value: "name" },
      {
        text: "Contact Name",
        value: "contact_name",
        width: "15%",
      },
      {
        text: "Contact Number",
        value: "contact_num",
        width: "20%",
      },
      { text: "Country", value: "country" },
      {
        text: "Address",
        value: "address",
        width: "160px",
      },
      { text: "Status", value: "status_name" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    desserts: [],
    currency_list: [],
    editedIndex: -1,
    editedItem: {
      cust_account: "",
      abbreviation: "",
      name: "",
      company_id: "",
      address: "",
      provice: "",
      zipcode: "",
      address_invoice: "",
      province_invoice: "",
      zipcode_invoice: "",
      tel: "",
      contact: "",
      tel_mobile: "",
      taxid: 0,
      branch: "",
      contact_name: "",
      payment_term: 0,
      status: "A",
      inserted_at: 0,
      user_create: 0,
      vat: 0,
      vat_type:"E",
      currency: "THB",
      country: "",
      country_invoice: "",
      fax: "",
      email: "",
      contact_position: ""
    },
    editedItem2: {
      name: "",
      company_id: "",
      address: "",
      provice: "",
      zipcode: "",
      address_invoice: "",
      province_invoice: "",
      zipcode_invoice: "",
      tel: "",
      contact: "",
      tel_mobile: "",
      taxid: 0,
      vat: 0,
      vat_type:"E",
      currency: "THB",
      branch: "",
      contact_name: "",
      payment_term: 0,
      status: 0,
      user_update: 0,
      country: "",
      country_invoice: "",
      fax: "",
      email: "",
      contact_position: ""
    },
    defaultItem: {
      cust_account: "",
      abbreviation: "",
      name: "",
      company_id: "",
      address: "",
      provice: "",
      zipcode: "",
      address_invoice: "",
      province_invoice: "",
      zipcode_invoice: "",
      tel: "",
      contact: "",
      tel_mobile: "",
      taxid: 0,
      vat_type:"E",
      branch: "",
      contact_name: "",
      payment_term: 0,
      status: 'A',
      user_update: 0,
      country: "",
      country_invoice: "",
      fax: "",
      email: "",
      contact_position: ""
    },
    dialogAdd: false,
    text_color: "text-h5 green--text text-center",
    title: "green",
    message: "green",
    fullPage: true,
    authorize_view: false,
    authorize_add: false,
    authorize_edit: false,
    authorize_del: false,
    authorize_id: 0,
    company: [],
    dialogDecComCode: false,
    commonRules: [(v1) => !!v1 || "This is required"],
    rules: {
      required: (value) => !!value || "This is required.",
      max: (v) => v.length <= 8 || "Max 8 characters",
    },
    telRules: [
                (v) => !!v || "This is required",
                (v) =>
                    /^([+-]*[0-9]+[+-]*)+$/.test(v) || "Please Input Number Only",
              ],
    emailRules: [
                (v1) => !!v1 || "This is required",
                (v) =>
                    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    v
                    ) || "Please check that the formate Email",
                ],
    rules2: [
      (value) => !!value || "This is required.",
      (value) => value.length <= 2 || "Max 2 characters",
    ],
    rules13: {
      required: (value) => !!value || "Required.",
      max: (v) => v.length <= 13 || "Max 13 characters",
    },
    emp_status: [
      { id: "A", status_name: "Active" },
      { id: "D", status_name: "Inactive" },
    ],
    editedItemDel: {
      status: "D",
      user_update: 0,
    },
  }),
  components: {
    SuccessDialog,
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Customer" : "Edit Customer";
    },

    filteredData() {
      return this.desserts.filter((row) => {
        const cust_account = row.cust_account?.toLowerCase();
        const abbreviation = row.abbreviation?.toLowerCase();
        const name = row.name?.toLowerCase();
        const company_id = row.company_id?.toLowerCase();
        const keyword = this.search.toLowerCase();

        return (
          cust_account.includes(keyword) ||
          abbreviation.includes(keyword) ||
          name.includes(keyword) ||
          company_id.includes(keyword)
        );
      });
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize();
  },
  async beforeCreate() {
    this.$store.state.navMenu = true;
  },
  async mounted() {
    await api.checkVersion();
    // ----------------- Check Authorize ---------------------------
    const userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);
    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed",
        "Please Logout And Login Again",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(userId, router_path);

    // console.log("res_auth:" + JSON.stringify(res_auth.data));

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    // console.log("res_auth:" + JSON.stringify(res_auth.data));
    // console.log("authorize_view:" + this.authorize_view);
    // console.log("authorize_add:" + this.authorize_add);
    // console.log("authorize_edit:" + this.authorize_edit);
    // console.log("authorize_del:" + this.authorize_del);
    // this.$router.back();

       if (!this.authorize_view) {
      this.$router.push("/not-found");
    }

    // ----------------- Check Authorize ---------------------------

    let token = localStorage.getItem(server.USER_ID);
    await this.loadData();
    await this.loadCurrency();

    if (userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }

    let comp_id = localStorage.getItem(server.COMPANYID);
    await this.loadCompanyMaster(comp_id);
  },

  methods: {
    async checkAbb(){
      // alert(this.editedItem.abbreviation)
      
      if(this.editedItem.abbreviation != "" && this.editedItem.abbreviation != null){
        if(this.editedIndex > -1){
          const cus_id = this.desserts[this.editedIndex].id;
          const res_by_id = await api.getCustomersById(cus_id);
          // console.log(res_by_id.data[0].abbreviation)
          if(this.editedItem.abbreviation.toUpperCase() != res_by_id.data[0].abbreviation ){
            const res_cd = await api.getCustomerAbb(this.editedItem.abbreviation)
            // console.log(res_c.data[0].status_abb)
            if(res_cd.data[0].status_abb > 0){
              this.$store.state.global_dialog = true;
              this.setupAlertDialog(
                true,
                "Failed",
                "ข้อมูล Abbreviation ซ้ำ",
                "text-h5 red--text text-center"
              );
              return;
            }    
          }
        }else{
            const res_c = await api.getCustomerAbb(this.editedItem.abbreviation)
            // console.log(res_c.data[0].status_abb)
            if(res_c.data[0].status_abb > 0){
              this.$store.state.global_dialog = true;
              this.setupAlertDialog(
                true,
                "Failed",
                "ข้อมูล Abbreviation ซ้ำ",
                "text-h5 red--text text-center"
              );
              return;
            }
        }
      }
      
    },
    async loadCurrency(){
      const res_cur = await api.getDataCurrency()
      console.log("res_cur.data")
      console.log(res_cur.data)
      this.currency_list = res_cur.data;
    },
    async setcuscode(){
      const res_dcode = await api.getCustomerLast()
      let dcode = [];
      if(res_dcode.data[0].cust_account){
        dcode = res_dcode.data[0].cust_account.split("D");
        let num = "";
        num = (Number(dcode[1]) + 1).toString();
        this.editedItem.cust_account = `D${num}`;
      }else{
        this.editedItem.cust_account = "D0001"
      }
     
    },
    async copy_adrr(){
      this.disabled_copy = true;
      this.editedItem.address_invoice = this.editedItem.address;
      this.editedItem.province_invoice = this.editedItem.provice;
      this.editedItem.country_invoice = this.editedItem.country;
      this.editedItem.zipcode_invoice = this.editedItem.zipcode;
    },
    async copy_adrrtrue(){
      this.disabled_copy = false;
      this.check_coppy = false;
    },
    async loadCompanyMaster(id) {
      const result = await api.getCompanyMaster(id);
      this.company = result.data;
    },
    async loadData() {
      const result = await api.getAllCustomerListByCompany(
        localStorage.getItem(server.COMPANYID)
      );
      this.desserts = result.data;
      this.desserts.forEach((item) => {
        if (item.tel) {
          if (item.contact) {
            if (item.tel_mobile) {
              item.contact_num = `${item.tel}, ${item.contact}, ${item.tel_mobile}`;
            } else {
              item.contact_num = `${item.tel}, ${item.contact}`;
            }
          } else {
            if (item.tel_mobile) {
              item.contact_num = `${item.tel}, ${item.tel_mobile}`;
            } else {
              item.contact_num = `${item.tel}`;
            }
          }
        } else {
          if (item.contact) {
            if (item.tel_mobile) {
              item.contact_num = `${item.contact}, ${item.tel_mobile}`;
            } else {
              item.contact_num = ` ${item.contact}`;
            }
          } else {
            if (item.tel_mobile) {
              item.contact_num = `${item.tel_mobile}`;
            } else {
              item.contact_num = ``;
            }
          }
        }
      });
      // this.$showLoader();
      this.$hideLoader();
    },
    initialize() {
      this.desserts = [
        {
          cust_account: "",
          abbreviation: "",
          name: "",
          company_id: "",
          address: "",
          provice: "",
          zipcode: "",
          address_invoice: "",
          province_invoice: "",
          zipcode_invoice: "",
          tel: "",
          contact: "",
          tel_mobile: "",
          taxid: 0,
          branch: "",
          contact_name: "",
          payment_term: 0,
          status: 0,
          user_update: 0,
        },
      ];
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      // const cus = this.desserts[this.editedIndex];
      // const result = await api.deleteCustomer(cus.id);
      const id = this.desserts[this.editedIndex].id;
      this.editedItemDel.user_update = localStorage.getItem(server.USER_ID);

      // alert(id);
      const res_del = await api.updateCustomer(id, this.editedItemDel);
      if (res_del.status == 200) {
        this.setupAlertDialog(
          true,
          "Success",
          "Delete data success",
          "text-h5 green--text text-center"
        );
        await this.loadData();
      } else {
        this.setupAlertDialog(
          true,
          "Failed",
          "Delete data Failed",
          "text-h5 red--text text-center"
        );
      }

      // this.initialize();
      this.closeDelete();
    },

    close() {
      this.$refs.form.reset()
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.disabled_copy = false;
        this.check_coppy = false
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    trimName(){
      this.editedItem.name = this.editedItem.name.trim();
    },
    async checkName (){ 
      const resChecKName = await api.getCustomerByName({
          params: {
            cus_name: this.editedItem.name,
            company_id: localStorage.getItem(server.COMPANYID),
          },
      })
      if(resChecKName.data.length > 0){
        if(this.editedIndex > -1){
          console.log("this.desserts[this.editedIndex].id: " + this.desserts[this.editedIndex].id)
          console.log(resChecKName.data)
          if(this.desserts[this.editedIndex].id == resChecKName.data[0].id){
            return false;
          }else{
            return true;
          }
        }else{
          return true;
        }
      }else{
        return false;
      }
    },
    async save() {

      var resNameCheck = await this.checkName();

      if (resNameCheck) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please verify if the Customer Name already exists.",
          "text-h5 red--text text-center"
        );
        this.$refs["Customer_Name"].focus();
        return;
      }
      if (!(this.editedIndex > -1)){
        const resCheckDoc = await api.checkDocRunning("Customer",localStorage.getItem(server.COMPANYID))
        if(resCheckDoc.status == 200){
          if(!resCheckDoc.data[0].resCheckDoc){
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Information Saved",
              "Please kindly check the value of document running.",
              "text-h5 red--text text-center"
            );
            return;
          }
        }else{
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Information Saved",
            "ไม่สามารถบันทึกได้",
            "text-h5 red--text text-center"
          );
          return;
        }
      }
      if (this.editedItem.name == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["Customer_Name"].focus();
        return;
      }
      if (this.editedItem.company_id == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["Company"].focus();
        return;
      }
      if (this.editedItem.address == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["Address"].focus();
        return;
      }
      if (this.editedItem.provice == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["Province"].focus();
        return;
      }
      if (this.editedItem.country == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["Country"].focus();
        return;
      }
      if (!(/^([+-]*[0-9]+[+-]*)+$/.test(this.editedItem.zipcode))) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["Postcode"].focus();
        return;
      }
      if (
          !/^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            this.editedItem.email
          )
      ) {
          this.checkDialogRegister = 1;
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
          );
          this.$refs[`E-mail`].focus();
          return;
      }
      if (!(/^([+-]*[0-9]+[+-]*)+$/.test(this.editedItem.tel))) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["Telephone_Number"].focus();
        return;
      }
      if (!(/^([+-]*[0-9]+[+-]*)+$/.test(this.editedItem.contact))) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["Contact_Number"].focus();
        return;
      }
      if (!(/^([+-]*[0-9]+[+-]*)+$/.test(this.editedItem.tel_mobile))) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["Mobile_Number"].focus();
        return;
      }
      if (this.editedItem.address_invoice == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["Address_Inv"].focus();
        return;
      }
      if (this.editedItem.province_invoice == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["Province_Inv"].focus();
        return;
      }
      if (this.editedItem.country_invoice == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["Country_Inv"].focus();
        return;
      }
      if (!(/^([+-]*[0-9]+[+-]*)+$/.test(this.editedItem.zipcode_invoice))) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["Postcode_Inv"].focus();
        return;
      }
      if (this.editedItem.currency == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["Currency"].focus();
        return;
      }
      if (this.editedItem.branch == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["Branch"].focus();
        return;
      }
      if (this.editedItem.contact_name == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["Contact_Name"].focus();
        return;
      }
      if (this.editedItem.contact_position == "" || this.editedItem.contact_position == null) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please enter all the details.",
          "text-h5 red--text text-center"
        );
        this.$refs["Contact_Position"].focus();
        return;
      }
      if(this.editedItem.vat == ""){
        this.editedItem.vat = 0;
      }
      console.log("this.editedItem.contact_position: " +this.editedItem.contact_position)
      if(this.editedItem.abbreviation != "" && this.editedItem.abbreviation != null){
        if(this.editedIndex > -1){
          const cus_id = this.desserts[this.editedIndex].id;
          const res_by_id = await api.getCustomersById(cus_id);
          // console.log(res_by_id.data[0].abbreviation)
          if(this.editedItem.abbreviation.toUpperCase() != res_by_id.data[0].abbreviation ){
            const res_cd = await api.getCustomerAbb(this.editedItem.abbreviation)
            // console.log(res_c.data[0].status_abb)
            if(res_cd.data[0].status_abb > 0){
              this.$store.state.global_dialog = true;
              this.setupAlertDialog(
                true,
                "Failed",
                "Abbreviation Duplicate, Please enter all the details.",
                "text-h5 red--text text-center"
              );
              return;
            }    
          }
        }else{
            const res_c = await api.getCustomerAbb(this.editedItem.abbreviation)
            // console.log(res_c.data[0].status_abb)
            if(res_c.data[0].status_abb > 0){
              this.$store.state.global_dialog = true;
              this.setupAlertDialog(
                true,
                "Failed",
                "Abbreviation Duplicate, Please enter all the details.",
                "text-h5 red--text text-center"
              );
              return;
            }
        }
      }


    

      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });
      if (this.editedIndex > -1) {
        this.editedItem2.abbreviation = this.editedItem.abbreviation? this.editedItem.abbreviation.toUpperCase():"";
        this.editedItem2.name = this.editedItem.name;
        this.editedItem2.company_id = this.editedItem.company_id;
        this.editedItem2.address = this.editedItem.address;
        this.editedItem2.provice = this.editedItem.provice;
        this.editedItem2.country = this.editedItem.country;
        this.editedItem2.zipcode = this.editedItem.zipcode;
        this.editedItem2.address_invoice = this.editedItem.address_invoice;
        this.editedItem2.province_invoice = this.editedItem.province_invoice;
        this.editedItem2.country_invoice = this.editedItem.country_invoice;
        this.editedItem2.zipcode_invoice = this.editedItem.zipcode_invoice;
        this.editedItem2.tel = this.editedItem.tel;
        this.editedItem2.contact = this.editedItem.contact;
        this.editedItem2.tel_mobile = this.editedItem.tel_mobile;
        this.editedItem2.email = this.editedItem.email;
        this.editedItem2.fax = this.editedItem.fax;
        this.editedItem2.taxid = this.editedItem.taxid;
        this.editedItem2.branch = this.editedItem.branch;
        this.editedItem2.contact_name = this.editedItem.contact_name;
        this.editedItem2.contact_position = this.editedItem.contact_position;
        this.editedItem2.payment_term = this.editedItem.payment_term;
        this.editedItem2.currency = this.editedItem.currency;
        this.editedItem2.vat = this.editedItem.vat;
        this.editedItem2.vat_type = this.editedItem.vat_type;
        this.editedItem2.status = this.editedItem.status;
        this.editedItem2.user_update = localStorage.getItem(server.USER_ID);

        Object.assign(this.desserts[this.editedIndex], this.editedItem);
        const id = this.desserts[this.editedIndex].id;

        const res_edit = await api.updateCustomer(id, this.editedItem2);
        if (res_edit.data.message) {
          loader.hide();
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed",
            res_edit.data.message,
            "text-h5 red--text text-center"
          );
          await this.loadData();
          return;      
        } else {
          loader.hide();
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Success",
            "Edit data success",
            "text-h5 green--text text-center"
          );
          await this.loadData();
        }
      } else {
        // Insert Customer
        this.editedItem.abbreviation = this.editedItem.abbreviation? this.editedItem.abbreviation.toUpperCase():"";
        this.editedItem.user_create = localStorage.getItem(server.USER_ID);
        const res_add = await api.addCustomer(this.editedItem);
        if (res_add.data.message) {
          loader.hide();
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed",
            res_add.data.message,
            "text-h5 red--text text-center"
          );
          return;
        } else {
          loader.hide();
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Success",
            "Add data success",
            "text-h5 green--text text-center"
          );
          await this.loadData();
        }
      }
      
      this.close();
    },
    async desComCode(){
                this.dialogDecComCode = true;
            },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
  },
};
