//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const moment = require('moment-timezone');
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import api from "@/services/api";
import { server } from "@/services/constants";
import unity from "@/unity/unity";

export default {
  name: "invoice",
  data(vm) {
    return {
      otfCheck: false,
      sendotpdata:{
        otp1:"",
        otp2:"",
        otp3:"",
        otp4:"",
        otp5:"",
        otp6:""
      },
      showfilenameappointment:"",
      dataotp:[],
      dialogOTPVerification:false,
      userId: 0,
      authorize: [],
      authorize_view: false,
      authorize_add: false,
      authorize_edit: false,
      authorize_del: false,
      authorize_id: 0,
      menu: false,
      menu2: false,
      toggleEnable: false,
      search: "",
      datefrom: null,
      dateto: null,
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      seticonsearchmore: { icon: "mdi mdi-chevron-down", action: false },
      defaultlanguage: "en",
      // regexlang: "th",
      chooselanguage: [
        { name: "en", flag: "gb" },
        { name: "th", flag: "th" },
      ],
      aa: "สวัสดี",
      dialogEdit: false,
      dialogDelete: false,
      mDataArray: [
        // {
        //   file_no: "GA-001",
        //   client_name: "client_name1",
        //   company: "company1",
        //   id: "97",
        //   date_show: new Date(
        //     Date.now() - new Date().getTimezoneOffset() * 60000
        //   )
        //     .toISOString()
        //     .substr(0, 10),
        //   Company: "testcus1",
        //   agency: "agency1",
        //   case_worker: "case_worker1",
        //   status: "Draft",
        // },
        // {
        //   file_no: "GA-002",
        //   client_name: "client_name2",
        //   company: "cus123",
        //   id: "97",
        //   date_show: new Date(
        //     Date.now() - new Date().getTimezoneOffset() * 60000
        //   )
        //     .toISOString()
        //     .substr(0, 10),
        //   Company: "company2",
        //   agency: "agency2",
        //   case_worker: "case_worker2",
        //   status: "Draft",
        // },
      ],

      headers: [
        {
          text: "File No.",
          value: "file_number",
          class: "bg-colorth tabletextwhite",
          width: "100px"
        },
        {
          text: "Client Name",
          value: "client_name",
          class: "bg-colorth tabletextwhite",
          width: "150px"
        },
        {
          text: "Customer Name",
          value: "customer_name",
          class: "bg-colorth tabletextwhite",
          width: "150px"
        },
        {
          text: "Company Name",
          value: "company_name",
          class: "bg-colorth tabletextwhite",
          width: "150px"
        },
        {
          text: "Agency",
          value: "file_type",
          class: "bg-colorth tabletextwhite",
          width: "100px"
        },
        {
          text: "Case Worker",
          value: "case_worker",
          class: "bg-colorth tabletextwhite",
          width: "120px"
        },
        {
          text: "Status",
          value: "status",
          class: "bg-colorth tabletextwhite",
          width: "90px"
        },

        {
          text: "",
          value: "actions",
          class: "bg-colorth tabletextwhite",
        },
      ],
      headerstransaction: [
        {
          text: "ID.",
          value: "id",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Work Type",
          value: "work_type",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "File No",
          value: "file_no",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Client Name",
          value: "client_name",
          class: "bg-colorth tabletextwhite",
        },

        {
          text: "Customer Name",
          value: "customer_name",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Status",
          value: "status",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "",
          value: "actions",
          class: "bg-colorth tabletextwhite",
        },
      ],
      editedIndex: -1,
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      mDataArraytransaction: [],
      dialogtransaction: false,

      countdown: 60, // เริ่มต้นที่ 60 วินาที
      timer: null,
    };
  },
  async beforeCreate() {
    this.$store.state.navMenu = true;
  },
  async created() {
    // this.initialize();
  },
  computed: {
    computeddatefromFormatted() {
      return unity.formatDate(this.datefrom);
    },
    computeddateToFormatted() {
      return unity.formatDate(this.dateto);
    },
    itemsForSelected() {
      if (this.search.length) {
        return this.dataSource.filter((item) => this.search.includes(item));
      }
      return this.dataSource;
    },
    formattedCountdown() {
      const minutes = Math.floor(this.countdown / 60);
      const seconds = this.countdown % 60;
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    },
  },
  components: {
    SuccessDialog,
    SuccessDialogPush,
  },
  async mounted() {
    await api.checkVersion();
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);

    // // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed",
        "Please Logout And Login Again",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(this.userId, router_path);


    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    // // this.$router.back();

       if (!this.authorize_view) {
      this.$router.push("/not-found");
    }

    // // ----------------- Check Authorize ---------------------------

    // let comp_id = localStorage.getItem(server.COMPANYID);

    this.userId = localStorage.getItem(server.USER_ID);
    if (this.userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
    await this.checkotpverification();
    await this.getAlllist();
    this.$hideLoader();
  },
  methods: {
    resendOTP() {
      this.sendotpdata = { otp1: "", otp2: "", otp3: "", otp4: "", otp5: "", otp6: "" };
      this.checkotpverification();
      this.startCountdown();
      
    },
    startCountdown() {
      if (this.timer) clearInterval(this.timer);
      this.countdown = 60;
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.$showLoader()
          clearInterval(this.timer);
          this.$hideLoader()
        }
      }, 1000);
    },

    goToDashboard() {
      this.$router.push('/dashboard'); // '/dashboard' คือเส้นทาง (route) ของหน้า Dashboard
    },
    async opentransactionlist(file_number) {
      this.mDataArraytransaction = [];
      const result = await api.getfileno_im_transaction_Company({
        params: {
          file_no: file_number,
          company_id: localStorage.getItem(server.COMPANYID),
        },
      });
      if (result.data) {
        this.mDataArraytransaction = result.data;
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Show data ",
          "Data not found ",
          "text-h5 red--text text-center"
        );
        return;
      }
      this.dialogtransaction = true;
      // localStorage.setItem(server.MODELMENU, 6);
      // this.$router.push(`/appointment-trademark-list`);
    },
    async closedialogtransaction() {
      this.dialogtransaction = false;
    },
    async deleteItem(id) {
      this.editedIndex = id;
      this.dialogDelete = true;
    },
    async closeDelete() {
      this.editedIndex = -1;
      this.dialogDelete = false;
    },
    async deleteItemConfirm() {
      this.$showLoader();
      this.mDataArray.forEach((x, index) => {
        if (x.id == this.editedIndex) {
          this.mDataArray.splice(index, 1);
        }
      });

      var principal_id_del = this.editedIndex;
      var res_participant_id_del = await api.getdataByIdim_participant(this.editedIndex);
      
   

      await api.deleteim_participant(this.editedIndex);

      const res_del = await api.deleteim_principal(this.editedIndex);

      this.closeDelete();
      if (res_del.status == 200 || res_del.status == 201) {

        var dataAdd = {
            user_id: localStorage.getItem(server.USER_ID),
            log_in_out_id: localStorage.getItem(server.LOGIN_ID),
            action: "Delete",
            table_name: "IM_Principal",
            table_id: principal_id_del,
            date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        }

        await api.addTransectionLog(dataAdd)

        var dataEditLog = {
          last_update: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        }
        await api.updateLogInOutLog(localStorage.getItem(server.LOGIN_ID),dataEditLog)

        for (const [index, item] of res_participant_id_del.data.entries()){
          var dataAddParticipant = {
              user_id: localStorage.getItem(server.USER_ID),
              log_in_out_id: localStorage.getItem(server.LOGIN_ID),
              action: "Delete",
              table_name: "IM_Participant",
              table_id: item.id,
              date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
          }
          await api.addTransectionLog(dataAddParticipant)

          var dataEditLog = {
            last_update: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
          }
          await api.updateLogInOutLog(localStorage.getItem(server.LOGIN_ID),dataEditLog)
        }
        this.$hideLoader();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Success",
          "Delete data success",
          "text-h5 green--text text-center"
        );
        this.initialize();
      } else {
        this.$hideLoader();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Delete data Failed",
          "text-h5 red--text text-center"
        );
      }
    },
    async sendOTPConfirm(){
      let allotpnumber = this.sendotpdata.otp1+this.sendotpdata.otp2+this.sendotpdata.otp3+this.sendotpdata.otp4+this.sendotpdata.otp5+this.sendotpdata.otp6;
      if(allotpnumber == this.dataotp.otp_number){
        const result = await api.updateotpverification(this.dataotp.user_otp_verification_id,{status:2});
      if(result.status == 200 || result.status == 201) {
        this.dialogOTPVerification = false;
        this.otfCheck = true;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Success",
          "OTP Verification success",
          "text-h5 green--text text-center"
        );
      }else{
        // this.dialogOTPVerification = false;
        // this.$store.state.global_push_dialog = true;
        //   this.setupAlertDialogPush(
        //     true,
        //     "Failed!!!",
        //     "OTP Verification Failed",
        //     "text-h5 red--text text-center",
        //     "/dashboard"
        //   ); 
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed",
            "OTP Verification Failed",
            "text-h5 red--text text-center"
          );
      }
      }else{
        // this.dialogOTPVerification = false;
        this.sendotpdata ={
          otp1: "",
          otp2: "",
          otp3: "",
          otp4: "",
          otp5: "",
          otp6: ""
        },
        this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed",
            "OTP Verification Failed",
            "text-h5 red--text text-center"
          );
      }
     
     
    },
    async checkotpverification() {
      const result = await api.getcheckotpverification(localStorage.getItem(server.USER_ID));
      if(result.data.length > 0 && result.data[0].status == 1) {
        this.dataotp = result.data[0];
        // this.$nextTick(() => this.$refs.otp1.$refs.input.focus());
        this.dialogOTPVerification = true;
       
      }else{
        this.otfCheck = true;
      }
    },
    async getAlllist() {
      const result = await api.getAllim_principal_Company({
        params: {
          company_id: localStorage.getItem(server.COMPANYID),
        },
      });
      this.mDataArray = result.data;
    },
    opentransactionadd(file_number) {
      this.$router.push(`/immigration-transaction-add/${file_number}`);
    },
    openeditItem(id) {
      this.$router.push(`/immigration-master-edit/${id}`);
    },
    openeditTabletransaction(transaction_id) {
      window.open(`/immigration-transaction-edit/${transaction_id}`);
    },
    showsearchmore() {
      this.toggleEnable = !this.toggleEnable;
      this.seticonsearchmore.action = !this.seticonsearchmore.action;
      if (this.seticonsearchmore.action === true) {
        this.seticonsearchmore.icon = "mdi mdi-chevron-up";
      } else {
        this.seticonsearchmore.icon = "mdi mdi-chevron-down";
      }
    },
    editItem() {},
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month - 1}-${day}`;
    },
    async setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    async setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
  },
  watch: {
    dialogOTPVerification(val) {
      if (val) this.startCountdown();
    },
  },

};
