import httpClient from "@/services/httpClient";
import {
  server
} from "@/services/constants";

export const getCustomers = () => {
  return httpClient.get(server.CUSTOMER_URL + `/get/list`);
};
export const getCustomerLast = () => {
  return httpClient.get(server.CUSTOMER_URL + `/get/last`);
};
export const getCustomerAbb = (abb) => {
  return httpClient.get(server.CUSTOMER_URL + `/get/abb/${abb}`);
};

export const getCustomersById = (id) => {
  return httpClient.get(server.CUSTOMER_URL + `/get/${id}`);
};

export const getcomtocus = (id) => {
  return httpClient.get(server.CUSTOMER_URL + `/getcomtocus/${id}`);
};

export const getAllCustomerList = () => {
  return httpClient.get(server.CUSTOMER_URL + `/getAllCustomerList`);
};
export const getAllCustomerListByCompany = (comapny_id) => {
  return httpClient.get(server.CUSTOMER_URL + `/getAllCustomerListBycompanyId/${comapny_id}`);
};

export const getAllCustomerListCompany = (query) => {
  return httpClient.get(server.CUSTOMER_URL + `/getAllCustomerListCompany`, query);
};
export const getCustomerByName = (query) => {
  return httpClient.get(server.CUSTOMER_URL + `/get-by-customer-name`, query);
};

export const getAllCustomerListactive = () => {
  return httpClient.get(server.CUSTOMER_URL + `/getAllCustomerListactive`);
};

export const getAllCustomerListactiveCompany = (query) => {
  return httpClient.get(server.CUSTOMER_URL + `/getAllCustomerListactiveCompany`, query);
};

export const addCustomer = (data) => {
  return httpClient.post(server.CUSTOMER_URL + `/addCustomer`, data);
};

export const updateCustomer = (id, data) => {
  return httpClient.put(server.CUSTOMER_URL + `/${id}`, data);
};

export const createCustomer = (data) => {
  return httpClient.post(server.CUSTOMER_URL, data);
};

export const deleteCustomer = (id) => {
  return httpClient.delete(server.CUSTOMER_URL + `/${id}`);
};

// export const checkttoken = (id) => {
//   return httpClient.get(server.CUSTOMER_URL + `/checktoken`);
// };