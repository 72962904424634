//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "@/services/constants";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import moment from 'moment-timezone';

export default {
  mounted() {
    if (api.isLoggedIn()) {
      this.$router.push("/dashboard");
    }
    
    this.checkDataOtp();
    this.$hideLoader();
  },
  components: { SuccessDialog, SuccessDialogPush },
  data() {
    return {
      mainId: 0,
      otpMain: 0,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      dialogAdd: false,
      isShowPassword: false,
      isShowPassword2: false,
      userId: 0,
      account: {
        password: "",
        confirm_password: "",
      },
    };
  },
  methods: {
    async checkDataOtp(){
      const resOtpcCheck = await api.loadOtpCheck(this.$route.params.id,this.$route.params.com_code)
      if(resOtpcCheck.status == 200){
        var dateNow = moment().tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm:ss");

        // แปลงวันที่เป็น Moment Object
        const momentNow = moment(dateNow, "DD/MM/YYYY HH:mm:ss");
        const momentCheck = moment(resOtpcCheck.data[0].dateCheck, "DD/MM/YYYY HH:mm:ss");

        // คำนวณความแตกต่างในหน่วยชั่วโมง
        const diffInHours = momentNow.diff(momentCheck, 'hours', true);

        if(diffInHours > 1){
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "Failed",
            "Please complete the task within 1 hour.",
            "text-h5 red--text text-center",
            "/login"
          );
        }else{
          this.mainId = resOtpcCheck.data[0].id;
          this.otpMain = resOtpcCheck.data[0].otp_number;
        }

      }else if(resOtpcCheck.status == 203){
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "Failed",
          "This user does not exist.",
          "text-h5 red--text text-center",
          "/login"
        );
      }else{
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "Failed",
          "This user does not exist.",
          "text-h5 red--text text-center",
          "/login"
        );
      }
    },
    async submitData() {

      this.userId = this.mainId;

      if (this.account.password == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please Input password",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (this.account.confirm_password == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please Input Confirm Password",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (this.account.password != this.account.confirm_password) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Password and Confirm Password not match",
          "text-h5 red--text text-center"
        );
        return;
      }

      const res_reset = await api.resetPasswordOtp(this.userId,this.otpMain,this.account);
      // alert(res_reset.status);
      if (res_reset.status == 200 || res_reset.status == 201) {
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "Success",
          "Update data success",
          "text-h5 green--text text-center",
          "/login"
        );
      } else if(res_reset.status == 203){
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Update data Failed, Username not found in the system.",
          "text-h5 red--text text-center"
        );

      }else{
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Update data Failed",
          "text-h5 red--text text-center"
        );
      }
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
  },
};
