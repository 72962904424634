import httpClient,{apiSMEMATE} from "@/services/httpClient";
import {
  server
} from "@/services/constants";

export const getAccounts = () => {
  return httpClient.get(server.ACCOUNT_URL + `/get`);
};
export const loadOtpCheck = (otp,com_code) => {
  return httpClient.get(server.ACCOUNT_URL + `/load-opt-check/${otp}/${com_code}`);
};

export const getAccountslist = () => {
  return httpClient.get(server.ACCOUNT_URL + `/get/list`);
};

export const getAccountsByCompany = (id) => {
  return httpClient.get(server.ACCOUNT_URL + `/getaccountbycompany/${id}`);
};
export const getCheckUsername = (username,com_id) => {
  return httpClient.get(server.ACCOUNT_URL + `/getcheckusername/${username}/${com_id}`);
};

export const getAccountid = (id) => {
  return httpClient.get(server.ACCOUNT_URL + `/${id}`);
};

export const getAccountByid = (id) => {
  return httpClient.get(server.ACCOUNT_URL + `/by/${id}`);
};

export const getAccountByidMenu = (id) => {
  return httpClient.get(server.ACCOUNT_URL + `//by-menu/${id}`);
};

export const updateAccount = (id, data) => {
  return httpClient.put(server.ACCOUNT_URL + `/${id}`, data);
};

export const updateUserAccount = (id, data) => {
  return httpClient.put(server.ACCOUNT_URL + `/updateUserAccount/${id}`, data);
};

export const replaceUserAccount1 = (id, data) => {
  return httpClient.put(
    server.ACCOUNT_URL + `/replaceUserAccount1/${id}`,
    data
  );
};

export const replaceUserAccount2 = (id, data) => {
  return httpClient.put(
    server.ACCOUNT_URL + `/replaceUserAccount2/${id}`,
    data
  );
};

export const replaceUserAccount3 = (id, data) => {
  return httpClient.put(
    server.ACCOUNT_URL + `/replaceUserAccount3/${id}`,
    data
  );
};

export const deleteUserAccount = (id, data) => {
  return httpClient.put(server.ACCOUNT_URL + `/deleteUserAccount/${id}`, data);
};

export const getAuthorize = (id, router_path) => {
  return httpClient.get(
    server.ACCOUNT_URL + `/getAuthorize/${id}/${router_path}`
  );
};

export const createAccount = (data) => {
  return httpClient.post(server.ACCOUNT_URL + "/register", data);
};

export const addUserAccount = (data) => {
  return httpClient.post(server.ACCOUNT_URL + "/addUserAccount", data);
};

export const deleteAccount = (id) => {
  return httpClient.delete(server.ACCOUNT_URL + `/${id}`);
};

export const getAccountDetail = (id) => {
  return httpClient.get(server.ACCOUNT_URL + `/${id}`);
};

// ---------------------------- set site company ----------------------------

export const getAccounts_by_ComID = (id) => {
  return httpClient.get(server.ACCOUNT_URL + `/get_by_ComID/${id}`);
};

export const getAccountslist_by_ComID = (id) => {
  return httpClient.get(server.ACCOUNT_URL + `/get/list_by_ComID/${id}`);
};
export const getAccountslist_by_DepID = (depid) => {
  return httpClient.get(server.ACCOUNT_URL + `/get/list_by_depid/${depid}`);
};
// ---------------------------- set site company ----------------------------

export const sendMailResetPassword = (data) => {
  return httpClient.post(server.ACCOUNT_URL + `/sendMailResetPassword`, data);
};

export const resetPassword = (id, data) => {
  return httpClient.put(server.ACCOUNT_URL + `/resetPassword/${id}`, data);
};
export const resetPasswordOtp = (id,otp,data) => {
  return httpClient.put(server.ACCOUNT_URL + `/resetPassword-otp/${id}/${otp}`, data);
};

export const getUserByEmail = (email) => {
  return httpClient.get(server.ACCOUNT_URL + `/getUserByEmail/${email}`);
};
export const getUserByEmailComCode = (email,com_code) => {
  return httpClient.get(server.ACCOUNT_URL + `/getUserByEmailandComCode/${email}/${com_code}`);
};
export const getUserByEmailComID = (email,com_id) => {
  return httpClient.get(server.ACCOUNT_URL + `/getUserByEmailandComID/${email}/${com_id}`);
};

export const getAccountsByCompanyImmogration = (id) => {
  return httpClient.get(server.ACCOUNT_URL + `/getaccountbycompanyimmigration/${id}`);
};

//--------------------sme-------------------//

export const addUserSmeMate = (data) => {
  return apiSMEMATE.post("/user/add", data);
};
export const editUserSmeMate = (id,data) => {
  return apiSMEMATE.put(`/user/${id}`, data);
};

export const updateMyprofile = (id, data) => {
  return httpClient.put(server.ACCOUNT_URL + `/updateMyprofile/${id}`, data);
};

export const getcheckotpverification = (id) => {
  return httpClient.get(server.ACCOUNT_URL + `/checkotpverificationimmasterandtransaction/${id}`);
};

export const updateotpverification = (id,data) => {
  return httpClient.put(server.ACCOUNT_URL + `/updateotpverificationimmasterandtransaction/${id}`,data);
};
export const updateNewotpverification = (id) => {
  return httpClient.get(server.ACCOUNT_URL + `/updateNewOtp/${id}`);
};