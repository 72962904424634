//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "@/services/constants";
import SuccessDialog from "@/components/cards/SuccessDialog";

export default {
  mounted() {
    if (api.isLoggedIn()) {
      this.$router.push("/dashboard");
    }

    this.$hideLoader();
  },
  components: { SuccessDialog },
  data() {
    return {
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      dialogAdd: false,
      isShowPassword: false,
      account: {
        email: "",
        com_code: ""
      },
    };
  },
  methods: {
    async submit() {
      if (this.account.com_code == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please Input Company Code",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.account.email == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please Input Email",
          "text-h5 red--text text-center"
        );
        return;
      }

      const res_reset = await api.getUserByEmailComCode(this.account.email,this.account.com_code);
      console.log(res_reset);
      // alert(res_edit.status);
      // console.log("res_reset", JSON.stringify(res_reset.data));
      // alert(res_reset.data.length);
      // if (res_reset.data.length > 0) {
      //   alert("Please check your email");
      // } else {
      //   alert("No data");
      // }
      if (res_reset.data.length > 0) {
        // this.$store.state.global_push_dialog = true;
        // this.setupAlertDialog(
        //   true,
        //   "Success",
        //   "Update data success",
        //   "text-h5 green--text text-center"
        // );
        //Send Mail
        this.$showLoader();
        var payload = {
          com_code: this.account.com_code,
          email: this.account.email,
          id: res_reset.data[0].id,
          subject: "Reset Password",
          message: "Please click link below to reset password",
          link: location.protocol + "//" + location.host,
        };
        const res_sendmail = await api.sendMailResetPassword(payload);
        this.$hideLoader();
        // console.log("res_sendmail", JSON.stringify(res_sendmail.data));
        // console.log("res_sendmail", res_sendmail.data.link);
        if (res_sendmail.data.link == true) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Success",
            res_sendmail.data.message,
            "text-h5 green--text text-center"
          );
        } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed",
            res_sendmail.data.message,
            "text-h5 red--text text-center"
          );
        }
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Email not found",
          "text-h5 red--text text-center"
        );
      }
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
  },
};
